import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBTP-UKkr9P15kaLxuKnBi3oRIo45a58ao",
  authDomain: "film-tv-production-app.firebaseapp.com",
  projectId: "film-tv-production-app",
  storageBucket: "film-tv-production-app.appspot.com",
  messagingSenderId: "8699110441",
  appId: "1:8699110441:web:4c075ceaa5293944692838",
  measurementId: "G-JTGPJYN2V1"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, auth, db, storage };