import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, getDoc, doc, orderBy, limit, Timestamp } from 'firebase/firestore';
import { db } from '../firebase'; // Make sure this path is correct
import Sidebar from '../components/Sidebar';
import { useAuth } from '../hooks/useAuth'; // Add this import
import './Dashboard.css'; // Optional: for custom styles
import '../styles/SharedStyles.css';
import { Link } from 'react-router-dom';
import { updateSupervisorProduction } from '../utils/updateSupervisor';

function Dashboard() {
  const { user } = useAuth(); // Add this line to get the user object
  const [activeProductions, setActiveProductions] = useState(0);
  const [totalCrewMembers, setTotalCrewMembers] = useState(0);
  const [upcomingShoots, setUpcomingShoots] = useState(0);
  const [productionStatus, setProductionStatus] = useState({
    preProduction: 0,
    inProduction: 0,
    postProduction: 0
  });
  const [recentActivities, setRecentActivities] = useState([]);
  const [crewMemberCount, setCrewMemberCount] = useState(0);
  const [departmentCrewMembers, setDepartmentCrewMembers] = useState([]);
  const [pendingTimesheets, setPendingTimesheets] = useState(0);
  const [departmentVehicles, setDepartmentVehicles] = useState([]);
  const [recentDispatchedRuns, setRecentDispatchedRuns] = useState([]);
  const [departmentName, setDepartmentName] = useState('');
  const [pendingTimeUsers, setPendingTimeUsers] = useState(new Set());
  const [crewMembers, setCrewMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        // Fetch active productions
        const productionsQuery = query(collection(db, 'productions'), where('status', '==', 'active'));
        const productionsSnapshot = await getDocs(productionsQuery);
        setActiveProductions(productionsSnapshot.size);

        // Fetch total crew members
        const crewMembersSnapshot = await getDocs(collection(db, 'crewMembers'));
        setTotalCrewMembers(crewMembersSnapshot.size);

        // Fetch upcoming shoots
        const today = new Date();
        const shootsQuery = query(
          collection(db, 'shoots'),
          where('date', '>', today)
        );
        const shootsSnapshot = await getDocs(shootsQuery);
        setUpcomingShoots(shootsSnapshot.size);

        // Fetch production status
        const statusSnapshot = await getDoc(doc(db, 'statistics', 'productionStatus'));
        if (statusSnapshot.exists()) {
          setProductionStatus(statusSnapshot.data());
        }

        // Fetch recent activities
        const activitiesQuery = query(
          collection(db, 'activities'),
          where('timestamp', '>', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))
        );
        const activitiesSnapshot = await getDocs(activitiesQuery);
        const recentActivitiesList = activitiesSnapshot.docs.map(doc => doc.data().description);
        setRecentActivities(recentActivitiesList);

      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  useEffect(() => {
    const fetchCrewMembers = async () => {
      try {
        // Check if we have both required values
        if (!user?.department || !user?.production) {
          console.log('Waiting for user data to load...');
          setCrewMembers([]);
          return;
        }

        const crewRef = collection(db, 'users');
        const q = query(
          crewRef,
          where('department', '==', user.department),
          where('production', '==', user.production),
          where('role', '==', 'Crew')
        );

        const querySnapshot = await getDocs(q);
        const crew = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setCrewMembers(crew);
      } catch (error) {
        console.error('Error fetching crew members:', error);
        setCrewMembers([]);
      }
    };

    // Only fetch when we have all required user data
    if (user?.department && user?.production) {
      fetchCrewMembers();
    }
  }, [user]); // Add user as dependency

  useEffect(() => {
    const fetchSupervisorData = async () => {
      if (!user) {
        console.log('No user data available');
        return;
      }

      // Log the entire user object to see what we have
      console.log('Current user data:', {
        uid: user.uid,
        email: user.email,
        role: user.role,
        department: user.department,
        production: user.production,
        fullUser: user // Log the entire user object
      });

      if (user.role !== 'Supervisor') {
        console.log('User is not a supervisor');
        return;
      }

      try {
        // Fetch department name
        if (user.department) {
          const departmentDoc = await getDoc(doc(db, 'departments', user.department));
          if (departmentDoc.exists()) {
            const deptName = departmentDoc.data().name;
            console.log('Department name:', deptName);
            setDepartmentName(deptName);
          }
        }

        // Log the query we're about to make
        console.log('Attempting to fetch crew with params:', {
          department: user.department,
          production: user.production || 'the big test show' // Fallback to known production name
        });

        // Fetch crew members
        const crewQuery = query(
          collection(db, 'users'),
          where('department', '==', user.department),
          where('production', '==', 'the big test show') // Hardcode for testing
        );

        const crewSnapshot = await getDocs(crewQuery);
        
        // Log the raw results
        console.log('Raw query results:', {
          totalFound: crewSnapshot.size,
          documents: crewSnapshot.docs.map(doc => ({
            id: doc.id,
            department: doc.data().department,
            production: doc.data().production,
            role: doc.data().role
          }))
        });

        const crewMembers = crewSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(member => member.role !== 'Supervisor');

        console.log('Filtered crew members:', {
          total: crewMembers.length,
          members: crewMembers
        });
        
        setDepartmentCrewMembers(crewMembers);

      } catch (error) {
        console.error("Error in fetchSupervisorData:", error);
        console.error("Error context:", {
          department: user?.department,
          production: user?.production || 'the big test show',
          error: error.message
        });
      }
    };

    fetchSupervisorData();
  }, [user]);

  useEffect(() => {
    const fetchDepartmentVehicles = async () => {
      if (!user) {
        console.log('No user data available');
        return;
      }

      try {
        // First, let's see ALL vehicles with detailed logging
        const allVehiclesQuery = query(
          collection(db, 'vehicles'),
          where('production', '==', 'the big test show')
        );

        const allVehiclesSnapshot = await getDocs(allVehiclesQuery);
        
        // More detailed logging of each vehicle
        console.log('Detailed Vehicle Information:', 
          allVehiclesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            dataKeys: Object.keys(doc.data()) // This will show us all fields present
          }))
        );

        // Try fetching with just the production filter first
        const vehiclesQuery = query(
          collection(db, 'vehicles'),
          where('production', '==', 'the big test show')
        );

        const vehicleSnapshot = await getDocs(vehiclesQuery);
        const vehicles = vehicleSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        console.log('Vehicles found:', {
          total: vehicles.length,
          vehicleDetails: vehicles.map(v => ({
            id: v.id,
            make: v.make,
            model: v.model,
            department: v.department,
            production: v.production
          }))
        });

        setDepartmentVehicles(vehicles);

      } catch (error) {
        console.error("Error fetching department vehicles:", error);
        console.error("Error details:", error.message);
      }
    };

    fetchDepartmentVehicles();
  }, [user]);

  useEffect(() => {
    const calculatePendingTimes = async () => {
      if (!user || !user.department) return;

      try {
        // Get yesterday's date at midnight
        const selectedDate = new Date();
        selectedDate.setDate(selectedDate.getDate() - 1);
        
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(23, 59, 59, 999);

        console.log('Checking times for:', selectedDate.toDateString());

        // Get crew members
        const crewQuery = query(
          collection(db, 'users'),
          where('department', '==', user.department),
          where('production', '==', 'the big test show')
        );
        
        const crewSnapshot = await getDocs(crewQuery);
        const crewMembers = crewSnapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }))
          .filter(member => member.role !== 'Supervisor');

        // Get all time entries for yesterday
        const timeEntriesQuery = query(
          collection(db, 'timeEntries'),
          where('date', '>=', Timestamp.fromDate(startOfDay)),
          where('date', '<=', Timestamp.fromDate(endOfDay)),
          orderBy('date', 'asc')
        );

        const timeSnapshot = await getDocs(timeEntriesQuery);
        const timeEntries = timeSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        console.log('Found time entries:', timeEntries);

        let pendingUsers = new Set();
        let pendingCount = 0;
        
        for (const member of crewMembers) {
          const hasValidEntry = timeEntries.some(entry => 
            entry.userId === member.id && 
            typeof entry.startTime !== 'undefined' && 
            typeof entry.endTime !== 'undefined'
          );

          if (!hasValidEntry) {
            pendingUsers.add(member.id);
            pendingCount++;
          }
        }

        setPendingTimeUsers(pendingUsers);
        setPendingTimesheets(pendingCount);

      } catch (error) {
        console.error("Error calculating pending times:", error);
      }
    };

    calculatePendingTimes();
  }, [user]);

  useEffect(() => {
    const fetchRecentDispatchedRuns = async () => {
      try {
        // Only proceed if user and user.department exist
        if (!user?.department) {
          console.log('User department not available');
          setRecentDispatchedRuns([]);
          return;
        }

        const dispatchRef = collection(db, 'dispatchedRuns');
        const q = query(
          dispatchRef,
          where('department', '==', user.department),
          orderBy('createdAt', 'desc'),
          limit(5)
        );

        const querySnapshot = await getDocs(q);
        const runs = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setRecentDispatchedRuns(runs);
      } catch (error) {
        console.error('Error fetching recent dispatched runs:', error);
        // Set empty array on error to prevent undefined issues
        setRecentDispatchedRuns([]);
      }
    };

    // Only fetch when user data is available
    if (user?.department) {
      fetchRecentDispatchedRuns();
    }
  }, [user]); // Add user as dependency

  // Render supervisor dashboard
  const renderSupervisorDashboard = () => (
    <div className="dashboard-content">
      <header className="page-header">
        <h1>Supervisor Dashboard</h1>
        <p>{departmentName} Department Overview</p>
      </header>
      <main>
        <div className="dashboard-grid">
          <div className="dashboard-card">
            <h3>Department Crew</h3>
            <p className="dashboard-number">{departmentCrewMembers.length}</p>
          </div>
          <div className="dashboard-card">
            <h3>Pending Times</h3>
            <p className="dashboard-number">{pendingTimesheets}</p>
          </div>
          <div className="dashboard-card">
            <h3>Department Vehicles</h3>
            <p className="dashboard-number">{departmentVehicles.length}</p>
          </div>
          <div className="dashboard-card large">
            <h3>Quick Actions</h3>
            <div className="quick-links-grid">
              <Link to="/dispatch" className="quick-link-card">
                <div className="icon-group">
                  <i className="fas fa-truck"></i>
                  <i className="fas fa-clock"></i>
                </div>
                <span>Create Dispatch</span>
              </Link>
              <Link to="/reports" className="quick-link-card">
                <i className="fas fa-chart-bar"></i>
                <span>View Reports</span>
              </Link>
              <Link to="/crew-members" className="quick-link-card">
                <i className="fas fa-users"></i>
                <span>Manage Crew</span>
              </Link>
              <Link to="/vehicles-page" className="quick-link-card">
                <i className="fas fa-car"></i>
                <span>Manage Vehicles</span>
              </Link>
              <Link to="/view-receipts" className="quick-link-card">
                <i className="fas fa-receipt"></i>
                <span>View Receipts</span>
              </Link>
            </div>
          </div>
          <div className="dashboard-card large">
            <h3>Recent Dispatched Runs</h3>
            <div className="recent-runs">
              {recentDispatchedRuns.length > 0 ? (
                recentDispatchedRuns.map(run => (
                  <div key={run.id} className="run-item">
                    <div className="run-header">
                      <span className="run-title">{run.title}</span>
                      <span className="run-date">
                        {run.date.toLocaleDateString('en-US', {
                          weekday: 'short',
                          month: 'short',
                          day: 'numeric',
                          hour: 'numeric',
                          minute: '2-digit'
                        })}
                      </span>
                    </div>
                    <p className="run-assigned">Assigned to: {run.assignedToName}</p>
                    <p className="run-description">{run.itemDescription}</p>
                    <p className="run-status">Status: {run.status}</p>
                  </div>
                ))
              ) : (
                <p>No recent dispatched runs</p>
              )}
            </div>
          </div>
        </div>
        <div className="dashboard-bottom">
          <div className="dashboard-crew-list">
            <h3>Department Crew Members</h3>
            <div className="crew-list">
              {departmentCrewMembers.map(member => (
                <div key={member.id} className="crew-item">
                  <div className={`status-indicator ${pendingTimeUsers.has(member.id) ? 'warning' : 'good'}`}></div>
                  <h4>{member.name}</h4>
                  <p>{member.role}</p>
                  {/* <p>Rate: ${member.rate}</p> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );

  // Main render logic
  return (
    <div className="dashboard-container">
      <Sidebar />
      {user?.role === 'Supervisor' ? (
        renderSupervisorDashboard()
      ) : (
        // Regular dashboard content
        <div className="dashboard-content">
          <header className="page-header">
            <h1>Dashboard</h1>
            <p>Overview of your production activities</p>
          </header>
          <main>
            <div className="dashboard-grid">
              <div className="dashboard-card">
                <h3>Active Productions</h3>
                <p className="dashboard-number">{activeProductions}</p>
              </div>
              <div className="dashboard-card">
                <h3>Total Crew Members</h3>
                <p className="dashboard-number">{crewMemberCount}</p>
              </div>
              <div className="dashboard-card">
                <h3>Upcoming Shoots</h3>
                <p className="dashboard-number">{upcomingShoots}</p>
              </div>
              <div className="dashboard-card large">
                <h3>Production Status</h3>
                <div className="production-counts">
                  <div>
                    <p>Pre-production</p>
                    <p className="dashboard-number">{productionStatus.preProduction}</p>
                  </div>
                  <div>
                    <p>In Production</p>
                    <p className="dashboard-number">{productionStatus.inProduction}</p>
                  </div>
                  <div>
                    <p>Post-production</p>
                    <p className="dashboard-number">{productionStatus.postProduction}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="dashboard-bottom">
              <div className="dashboard-recent">
                <h3>Recent Activities</h3>
                <ul>
                  {recentActivities.map((activity, index) => (
                    <li key={index}>{activity}</li>
                  ))}
                </ul>
              </div>
              <div className="dashboard-actions">
                <h3>Quick Actions</h3>
                <button className="dashboard-action-btn">Create New Production</button>
                <button className="dashboard-action-btn">Add Crew Member</button>
                <button className="dashboard-action-btn">View Reports</button>
                <Link to="/view-receipts" className="dashboard-action-btn">View Receipts</Link>
              </div>
            </div>
          </main>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
