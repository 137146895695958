import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs, query, where, orderBy, Timestamp, doc, getDoc, updateDoc, getFirestore } from 'firebase/firestore';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import { AuthContext } from '../contexts/AuthContext';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import './Reports.css';
import '../styles/SharedStyles.css';
import Toast from '../components/Toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

// Add these helper functions at the top
const calculateTotalHours = (entry) => {
  if (!entry.call || !entry.wrap) return 0;

  // Convert time strings (HH:MM) to decimal hours
  const getDecimalHours = (timeStr) => {
    if (!timeStr || typeof timeStr !== 'string') return 0;
    const [hours, minutes] = timeStr.split(':');
    return Number(hours) + (Number(minutes) / 60);
  };

  const callHours = getDecimalHours(entry.call);
  const wrapHours = getDecimalHours(entry.wrap);

  // Calculate the difference
  let totalHours = wrapHours - callHours;

  // Handle wrap time going into next day
  if (totalHours < 0) {
    totalHours += 24;
  }

  // Return rounded to 2 decimal places
  return Math.round(totalHours * 100) / 100;
};

function Reports() {
  const [timeEntries, setTimeEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const [departmentMap, setDepartmentMap] = useState({});
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [vehicleEntries, setVehicleEntries] = useState([]);
  const [toast, setToast] = useState(null);
  const [currentDepartment, setCurrentDepartment] = useState('');
  const [currentProduction, setCurrentProduction] = useState('');
  const [activeTab, setActiveTab] = useState('timeReports');
  const [selectedColumns, setSelectedColumns] = useState({
    name: true,
    union: true,
    call: true,
    wrap: true,
    totalHours: true,
    rate: true,
    vendor: true,
    towTag: true,
    trailerTag: true
  });

  useEffect(() => {
    if (user) {
      setCurrentDepartment(user.department || 'N/A');
      setCurrentProduction(user.production || 'N/A');
      fetchDepartments();
      fetchTimeEntries();
      fetchVehicleEntries();
      fetchHotSheetData();
    }
  }, [user]);

  // Add this state for vehicle report date if it's different from time report date
  const [vehicleReportDate, setVehicleReportDate] = useState(new Date());

  // Add these helper functions
  const getWeekDates = (date) => {
    const sunday = new Date(date);
    sunday.setDate(date.getDate() - date.getDay()); // Get Sunday of the week
    return sunday;
  };

  const formatWeekRange = (startDate) => {
    const endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 6);
    return `Week of ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
  };

  // Update the date navigation handlers
  const handlePreviousWeekVehicle = () => {
    const newDate = new Date(vehicleReportDate);
    newDate.setDate(newDate.getDate() - 7);
    setVehicleReportDate(getWeekDates(newDate));
  };

  const handleNextWeekVehicle = () => {
    const newDate = new Date(vehicleReportDate);
    newDate.setDate(newDate.getDate() + 7);
    setVehicleReportDate(getWeekDates(newDate));
  };

  const handleVehicleDateChange = (e) => {
    setVehicleReportDate(new Date(e.target.value));
  };

  // Add new state for hot sheet date and data
  const [hotSheetDate, setHotSheetDate] = useState(new Date());
  const [hotSheetData, setHotSheetData] = useState([]);

  // Add these state variables at the top
  const [hotSheetEntries, setHotSheetEntries] = useState([]);

  // Add this function to fetch hot sheet data
  const fetchHotSheetData = async () => {
    try {
      const startOfDay = new Date(hotSheetDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(hotSheetDate);
      endOfDay.setHours(23, 59, 59, 999);

      // Query time entries for the selected date
      const timeEntriesQuery = query(
        collection(db, 'timeEntries'),
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay))
      );

      const timeEntriesSnapshot = await getDocs(timeEntriesQuery);
      
      // Get all users to map their details
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const usersMap = {};
      usersSnapshot.docs.forEach(doc => {
        const userData = doc.data();
        usersMap[doc.id] = {
          name: userData.name,
          union: userData.union,
          rate: userData.rate
        };
      });

      // Process time entries with user data
      const entries = timeEntriesSnapshot.docs.map(doc => {
        const data = doc.data();
        const userData = usersMap[data.userId] || {};
        
        // Ensure rate is a number
        const rate = parseFloat(userData.rate) || 0;
        
        const totalHours = data.startTime && data.endTime ? 
          (data.endTime - data.startTime + (data.endTime < data.startTime ? 24 : 0)) : 
          0;
        
        const { regularPay, timeAndHalfPay, doubleTimePay, tripleTimePay, totalPay } = 
          calculatePay(totalHours, rate);

        return {
          id: doc.id,
          name: userData.name || 'Unknown',
          union: userData.union || 'N/A',
          call: formatTime(data.startTime),
          wrap: formatTime(data.endTime),
          totalHours,
          rate: rate, // Now guaranteed to be a number
          regularPay,
          timeAndHalfPay,
          doubleTimePay,
          tripleTimePay,
          totalPay
        };
      });

      setHotSheetEntries(entries);
    } catch (error) {
      console.error('Error fetching hot sheet data:', error);
      setError('Failed to fetch hot sheet data');
    }
  };

  // Add this helper function to calculate pay
  const calculatePay = (hours, rate) => {
    let regularHours = Math.min(8, hours);
    let timeAndHalfHours = hours > 8 ? Math.min(4, hours - 8) : 0;
    let doubleTimeHours = hours > 12 ? Math.min(4, hours - 12) : 0;
    let tripleTimeHours = hours > 16 ? hours - 16 : 0;

    return {
      regularPay: regularHours * rate,
      timeAndHalfPay: timeAndHalfHours * (rate * 1.5),
      doubleTimePay: doubleTimeHours * (rate * 2),
      tripleTimePay: tripleTimeHours * (rate * 3),
      totalPay: (regularHours * rate) + 
                (timeAndHalfHours * rate * 1.5) + 
                (doubleTimeHours * rate * 2) + 
                (tripleTimeHours * rate * 3)
    };
  };

  useEffect(() => {
    if (user) {
      fetchDepartments();
      fetchTimeEntries();
      fetchVehicleEntries();
      fetchHotSheetData();
    }
  }, [user, selectedDate, vehicleReportDate, hotSheetDate]);

  const fetchDepartments = async () => {
    try {
      const departmentsSnapshot = await getDocs(collection(db, 'departments'));
      const deptMap = {};
      departmentsSnapshot.forEach(doc => {
        deptMap[doc.id] = doc.data().name;
      });
      setDepartmentMap(deptMap);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchTimeEntries = async () => {
    try {
      setLoading(true);
      console.log("Fetching time entries for date:", selectedDate);

      // Define startOfDay and endOfDay
      const startOfDay = new Date(selectedDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(selectedDate);
      endOfDay.setHours(23, 59, 59, 999);

      // Get time entries
      let timeEntriesQuery = query(
        collection(db, 'timeEntries'),
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay)),
        orderBy('date', 'asc')
      );

      const querySnapshot = await getDocs(timeEntriesQuery);

      // Get all user data
      const userIds = new Set(querySnapshot.docs.map(doc => doc.data().userId));
      const userDocs = await Promise.all(
        Array.from(userIds).map(userId => getDoc(doc(db, 'users', userId)))
      );
      const userDataMap = Object.fromEntries(
        userDocs.map(userDoc => [userDoc.id, userDoc.data()])
      );

      // First get the specific vehicle we know exists
      const vehicleDoc = await getDoc(doc(db, 'vehicles', 'CaeCo8hL6cfLl6RXhRUn'));
      const vehicleData = vehicleDoc.exists() ? vehicleDoc.data() : null;
      console.log("Found vehicle data:", vehicleData);

      // Get vendor data
      const vendorDoc = await getDoc(doc(db, 'vendors', vehicleData.vendorId));
      const vendorData = vendorDoc.exists() ? vendorDoc.data() : null;

      // Create a mapping between different user IDs
      const userMappings = {
        'FMD2Nb0OcSME3pikCCdNsR4HRma2': 'AqwSkDnQ7NRN3hWw4vPlvEn7XBz2', // Map Stacy's time entry ID to vehicle assignment ID
      };

      const entries = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const userData = userDataMap[data.userId] || {};
        
        // Use the mapping to find the correct vehicle assignment
        const mappedUserId = userMappings[data.userId] || data.userId;
        const isAssignedVehicle = vehicleData && vehicleData.assignedTo === mappedUserId;

        return {
          id: doc.id,
          name: userData.name || `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 'Unknown',
          union: userData.union || 'N/A',
          callTime: data.startTime || 'N/A',
          wrapTime: data.endTime || 'N/A',
          rate: userData.rate || 'N/A',
          department: userData.department || 'N/A',
          production: userData.production || 'N/A',
          userId: data.userId,
          vendor: isAssignedVehicle ? vendorData?.name || 'Not assigned' : 'Not assigned',
          towTag: isAssignedVehicle ? vehicleData.unitNumber || 'No unit number' : 'No unit number',
          trailerTag: isAssignedVehicle ? vehicleData.licensePlate || 'No plate' : 'No plate',
          date: data.date.toDate()
        };
      });
      
      console.log("Processed time entries:", entries);
      setTimeEntries(entries);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching time entries:", error);
      setError(error);
      setLoading(false);
    }
  };

  const calculateTotalHours = (entry) => {
    if (entry.callTime === 'N/A' || entry.wrapTime === 'N/A') return 'N/A';
    const start = parseFloat(entry.callTime);
    const end = parseFloat(entry.wrapTime);
    if (isNaN(start) || isNaN(end)) return 'N/A';
    const diff = end - start;
    return diff.toFixed(2);
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  const handleDateChange = (e) => {
    setSelectedDate(new Date(e.target.value));
  };

  const handlePreviousDayReport = () => {
    const yesterday = new Date(selectedDate);
    yesterday.setDate(yesterday.getDate() - 1);
    setSelectedDate(yesterday);
  };

  const exportToPDF = () => {
    // Check if any columns are selected
    const anyColumnsSelected = Object.values(selectedColumns).some(value => value);
    if (!anyColumnsSelected) {
      setToast({ message: 'Please select at least one column to export', type: 'error' });
      return;
    }

    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });
    const dateStr = selectedDate.toISOString().split('T')[0];
    const departmentStr = departmentMap[currentDepartment] || currentDepartment;
    
    // Add header information
    doc.setFontSize(18);
    doc.text('Time Report', 14, 15);
    doc.setFontSize(12);
    doc.text(`Department: ${departmentStr}`, 14, 25);
    doc.text(`Production: ${currentProduction}`, 14, 32);
    doc.text(`Date: ${selectedDate.toDateString()}`, 14, 39);

    // Filter headers and data based on selected columns
    const headers = columns
      .filter(col => selectedColumns[col.id])
      .map(col => col.label);

    const data = timeEntries.map(entry => {
      return columns
        .filter(col => selectedColumns[col.id])
        .map(col => {
          switch(col.id) {
            case 'name': return entry.name;
            case 'union': return entry.union;
            case 'call': return formatTime(entry.callTime);
            case 'wrap': return formatTime(entry.wrapTime);
            case 'totalHours': return calculateTotalHours(entry);
            case 'rate': return `$${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr`;
            case 'vendor': return entry.vendor || '';
            case 'towTag': return entry.towTag || '';
            case 'trailerTag': return entry.trailerTag || '';
            default: return '';
          }
        });
    });

    doc.autoTable({
      startY: 45,
      head: [headers],
      body: data,
    });
    doc.save(`${departmentStr}_time_report_${dateStr}.pdf`);
  };

  const exportToExcel = () => {
    // Check if any columns are selected
    const anyColumnsSelected = Object.values(selectedColumns).some(value => value);
    if (!anyColumnsSelected) {
      setToast({ message: 'Please select at least one column to export', type: 'error' });
      return;
    }

    const dateStr = selectedDate.toISOString().split('T')[0];
    const headerData = [
      ['Time Report'],
      [`Department: ${departmentMap[currentDepartment] || currentDepartment}`],
      [`Production: ${currentProduction}`],
      [`Date: ${selectedDate.toDateString()}`],
      [], // Empty row for spacing
    ];

    // Filter headers based on selected columns
    const headers = columns
      .filter(col => selectedColumns[col.id])
      .map(col => col.label);

    // Filter data based on selected columns
    const data = timeEntries.map(entry => {
      return columns
        .filter(col => selectedColumns[col.id])
        .map(col => {
          switch(col.id) {
            case 'name': return entry.name;
            case 'union': return entry.union;
            case 'call': return formatTime(entry.callTime);
            case 'wrap': return formatTime(entry.wrapTime);
            case 'totalHours': return calculateTotalHours(entry);
            case 'rate': return `$${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr`;
            case 'vendor': return entry.vendor || '';
            case 'towTag': return entry.towTag || '';
            case 'trailerTag': return entry.trailerTag || '';
            default: return '';
          }
        });
    });

    const ws = XLSX.utils.aoa_to_sheet([
      ...headerData,
      headers,
      ...data
    ]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Time Report");
    XLSX.writeFile(wb, `time_report_${dateStr}.xlsx`);
  };

  const handleExportToGoogleSheets = () => {
    const dateStr = selectedDate.toISOString().split('T')[0];
    alert(`Export to Google Sheets for ${dateStr} is not yet implemented. This would typically involve using the Google Sheets API.`);
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) return 'N/A';
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  // Update fetchVehicleEntries to use vehicleReportDate
  const fetchVehicleEntries = async () => {
    try {
      setLoading(true);
      console.log("Fetching vehicle entries...");
      console.log("User production:", user.production);

      // Get all vehicles for the current production
      const vehiclesQuery = query(
        collection(db, 'vehicles'),
        where('productionId', '==', 'cib7OIsbumFffcRiyr0w')
      );
      
      const vehiclesSnapshot = await getDocs(vehiclesQuery);
      
      // Get all users for driver names
      const usersQuery = query(collection(db, 'users'));
      const usersSnapshot = await getDocs(usersQuery);
      const usersMap = {};
      usersSnapshot.docs.forEach(doc => {
        const userData = doc.data();
        usersMap[doc.id] = userData.name || 
          `${userData.firstName || ''} ${userData.lastName || ''}`.trim() || 
          'Not assigned';
      });

      // Get all vendors
      const vendorsQuery = query(collection(db, 'vendors'));
      const vendorsSnapshot = await getDocs(vendorsQuery);
      const vendorsMap = {};
      vendorsSnapshot.docs.forEach(doc => {
        vendorsMap[doc.id] = doc.data().name;
      });

      // Process vehicles with vendor and user data
      const entries = vehiclesSnapshot.docs.map(doc => {
        const vehicleData = doc.data();
        console.log("Processing vehicle:", vehicleData);
        
        return {
          id: doc.id,
          vendor: vehicleData.vendorId ? vendorsMap[vehicleData.vendorId] : 'Not assigned',
          unitNumber: vehicleData.unitNumber || 'No unit number',
          licensePlate: vehicleData.licensePlate || 'No plate',
          driverAssigned: vehicleData.assignedTo ? usersMap[vehicleData.assignedTo] : 'Not assigned',  // Use the usersMap here
          rentalStart: vehicleData.rentalStart || null,
          rentalEnd: vehicleData.rentalEnd || null,
          rentalRate: vehicleData.rentalRate || 'Not set',
          rentalPeriod: vehicleData.rentalPeriod || 'daily',
          status: vehicleData.status || 'Not set'
        };
      });

      console.log("Final entries:", entries);
      setVehicleEntries(entries);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching vehicle entries:", error);
      setError(error);
      setLoading(false);
    }
  };

  const exportVehiclesToPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    // Add header information
    doc.setFontSize(18);
    doc.text('Vehicle Report', 14, 15);
    doc.setFontSize(12);
    doc.text(`Department: ${departmentMap[user.department] || user.department}`, 14, 25);
    doc.text(`Production: ${user.production}`, 14, 32);
    doc.text(`Date: ${vehicleReportDate.toDateString()}`, 14, 39);

    // Define the table headers and data
    const headers = [
      ['Vendor', 'Unit Number', 'Tag #', 'Driver Assigned', 'Rental Rate', 'Rental Start', 'Rental End']
    ];

    const data = vehicleEntries.map(vehicle => [
      vehicle.vendor,
      vehicle.unitNumber,
      vehicle.licensePlate,
      vehicle.driverAssigned,
      vehicle.rentalRate !== 'Not set' ? `$${vehicle.rentalRate}/${vehicle.rentalPeriod}` : 'Not set',
      vehicle.rentalStart ? new Date(vehicle.rentalStart.seconds * 1000).toLocaleDateString() : 'Not set',
      vehicle.rentalEnd ? new Date(vehicle.rentalEnd.seconds * 1000).toLocaleDateString() : 'Not set'
    ]);

    doc.autoTable({
      startY: 45,
      head: headers,
      body: data,
    });

    doc.save(`vehicle_report_${vehicleReportDate.toISOString().split('T')[0]}.pdf`);
  };

  const exportVehiclesToExcel = () => {
    const dateStr = vehicleReportDate.toISOString().split('T')[0];
    
    // Create header rows
    const headerData = [
      ['Vehicle Report'],
      [`Department: ${departmentMap[user.department] || user.department}`],
      [`Production: ${user.production}`],
      [`Date: ${vehicleReportDate.toDateString()}`],
      [], // Empty row for spacing
      ['Vendor', 'Unit Number', 'Tag #', 'Driver Assigned', 'Rental Rate', 'Rental Start', 'Rental End']
    ];

    // Create data rows
    const data = vehicleEntries.map(vehicle => [
      vehicle.vendor,
      vehicle.unitNumber,
      vehicle.licensePlate,
      vehicle.driverAssigned,
      vehicle.rentalRate !== 'Not set' ? `$${vehicle.rentalRate}/${vehicle.rentalPeriod}` : 'Not set',
      vehicle.rentalStart ? new Date(vehicle.rentalStart.seconds * 1000).toLocaleDateString() : 'Not set',
      vehicle.rentalEnd ? new Date(vehicle.rentalEnd.seconds * 1000).toLocaleDateString() : 'Not set'
    ]);

    const ws = XLSX.utils.aoa_to_sheet([...headerData, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Vehicle Report");
    XLSX.writeFile(wb, `vehicle_report_${dateStr}.xlsx`);
  };

  // Rename this function to handleVehicleEntryDateChange
  const handleVehicleEntryDateChange = (id, field, value) => {
    // Basic validation for MM/DD/YY format
    if (/^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{2}$/.test(value) || value === '') {
      setVehicleEntries(entries =>
        entries.map(entry =>
          entry.id === id ? { ...entry, [field]: value } : entry
        )
      );
    }
  };

  // Add this before the export functions
  const columns = [
    { id: 'name', label: 'Name' },
    { id: 'union', label: 'Union' },
    { id: 'call', label: 'Call' },
    { id: 'wrap', label: 'Wrap' },
    { id: 'totalHours', label: 'Total Hours' },
    { id: 'rate', label: 'Rate' },
    { id: 'vendor', label: 'Vendor' },
    { id: 'towTag', label: 'Tow/Tag' },
    { id: 'trailerTag', label: 'Trailer/Tag' }
  ];

  const handleColumnToggle = (columnId) => {
    setSelectedColumns(prev => ({
      ...prev,
      [columnId]: !prev[columnId]
    }));
  };

  // Add this function to handle tab switching
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleRentalDateChange = async (vehicleId, field, date) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        [field]: date ? Timestamp.fromDate(date) : null
      });
      
      // Update local state
      setVehicleEntries(entries => 
        entries.map(vehicle => 
          vehicle.id === vehicleId 
            ? { ...vehicle, [field]: date }
            : vehicle
        )
      );
      
      setToast({ 
        message: 'Rental date updated successfully', 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error updating rental date:', error);
      setToast({ 
        message: 'Failed to update rental date', 
        type: 'error' 
      });
    }
  };

  // Update the calculateDailyCost function
  const calculateDailyCost = (vehicle, days) => {
    if (!vehicle.rentalRate) return null;
    const rate = parseFloat(vehicle.rentalRate);
    
    switch (vehicle.rentalPeriod) {
      case 'daily':
        return (rate * days).toFixed(2);
      case 'weekly':
        return (rate * Math.ceil(days / 7)).toFixed(2);
      case 'monthly':
        return (rate * Math.ceil(days / 30)).toFixed(2);
      default:
        return null;
    }
  };

  // Update the getRateDisplay function in the table
  const getRateDisplay = (vehicle) => {
    if (!vehicle.rentalRate) return 'Not set';
    
    const daysCount = daysCounts[vehicle.id] || 1;
    const cost = calculateDailyCost(vehicle, daysCount);
    
    if (vehicle.rentalPeriod === 'daily') {
      return `$${vehicle.rentalRate}/${vehicle.rentalPeriod} (${daysCount} days = $${cost})`;
    }
    
    if (vehicle.rentalPeriod === 'weekly') {
      const weeks = Math.ceil(daysCount / 7);
      return `$${vehicle.rentalRate}/${vehicle.rentalPeriod} (${weeks} weeks = $${cost})`;
    }
    
    if (vehicle.rentalPeriod === 'monthly') {
      const months = Math.ceil(daysCount / 30);
      return `$${vehicle.rentalRate}/${vehicle.rentalPeriod} (${months} months = $${cost})`;
    }
    
    return `$${vehicle.rentalRate}/${vehicle.rentalPeriod}`;
  };

  // Add this state at the top of the component with other state declarations
  const [daysCounts, setDaysCounts] = useState({});

  // Add this handler function
  const handleDaysChange = (vehicleId, days) => {
    setDaysCounts(prev => ({
      ...prev,
      [vehicleId]: parseInt(days)
    }));
  };

  // Add this function to calculate the status based on rental end date
  const getRentalStatus = (rentalEnd) => {
    if (!rentalEnd) return 'not-set';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const endDate = rentalEnd.toDate();
    endDate.setHours(0, 0, 0, 0);
    
    const diffDays = Math.floor((endDate - today) / (1000 * 60 * 60 * 24));
    
    if (diffDays < 0) return 'overdue';  // Past end date
    if (diffDays === 0) return 'ending';  // End date is today
    if (diffDays <= 5) return 'warning';  // 5 days or less
    return 'good';  // More than 5 days
  };

  // Add these functions near the other date handlers
  const handlePreviousDayHotSheet = () => {
    const newDate = new Date(hotSheetDate);
    newDate.setDate(newDate.getDate() - 1);
    setHotSheetDate(newDate);
  };

  const handleNextDayHotSheet = () => {
    const newDate = new Date(hotSheetDate);
    newDate.setDate(newDate.getDate() + 1);
    setHotSheetDate(newDate);
  };

  const handleHotSheetDateChange = (e) => {
    setHotSheetDate(new Date(e.target.value));
  };

  // Add these export functions
  const exportHotSheetToPDF = () => {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    // Add header information
    doc.setFontSize(18);
    doc.text('Hot Sheet', 14, 15);
    doc.setFontSize(12);
    doc.text(`Department: ${departmentMap[user.department] || user.department}`, 14, 25);
    doc.text(`Production: ${user.production}`, 14, 32);
    doc.text(`Date: ${hotSheetDate.toDateString()}`, 14, 39);

    // Define the table headers and data
    const headers = [
      ['Name', 'Union', 'Call', 'Wrap', 'Total Hours', 'Rate', 'Regular Pay', 'Time and Half Pay', 'Double Time Pay', 'Triple Time Pay', 'Total Pay']
    ];

    const data = hotSheetEntries.map(entry => [
      entry.name,
      entry.union,
      entry.call,
      entry.wrap,
      entry.totalHours.toFixed(2),
      `$${entry.rate.toFixed(2)}`,
      `$${entry.regularPay.toFixed(2)}`,
      `$${entry.timeAndHalfPay.toFixed(2)}`,
      `$${entry.doubleTimePay.toFixed(2)}`,
      `$${entry.tripleTimePay.toFixed(2)}`,
      `$${entry.totalPay.toFixed(2)}`
    ]);

    // Add totals row
    const totals = [
      'Daily Total:',
      '',
      '',
      '',
      '',
      '',
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.regularPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.timeAndHalfPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.doubleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.tripleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.totalPay, 0).toFixed(2)}`
    ];
    data.push(totals);

    doc.autoTable({
      startY: 45,
      head: headers,
      body: data,
    });

    doc.save(`hot_sheet_${hotSheetDate.toISOString().split('T')[0]}.pdf`);
  };

  const exportHotSheetToExcel = () => {
    const dateStr = hotSheetDate.toISOString().split('T')[0];
    
    // Create header rows
    const headerData = [
      ['Hot Sheet'],
      [`Department: ${departmentMap[user.department] || user.department}`],
      [`Production: ${user.production}`],
      [`Date: ${hotSheetDate.toDateString()}`],
      [], // Empty row for spacing
      ['Name', 'Union', 'Call', 'Wrap', 'Total Hours', 'Rate', 'Regular Pay', 'Time and Half Pay', 'Double Time Pay', 'Triple Time Pay', 'Total Pay']
    ];

    // Create data rows
    const data = hotSheetEntries.map(entry => [
      entry.name,
      entry.union,
      entry.call,
      entry.wrap,
      entry.totalHours.toFixed(2),
      `$${entry.rate.toFixed(2)}`,
      `$${entry.regularPay.toFixed(2)}`,
      `$${entry.timeAndHalfPay.toFixed(2)}`,
      `$${entry.doubleTimePay.toFixed(2)}`,
      `$${entry.tripleTimePay.toFixed(2)}`,
      `$${entry.totalPay.toFixed(2)}`
    ]);

    // Add totals row
    const totals = [
      'Daily Total:',
      '',
      '',
      '',
      '',
      '',
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.regularPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.timeAndHalfPay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.doubleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.tripleTimePay, 0).toFixed(2)}`,
      `$${hotSheetEntries.reduce((sum, entry) => sum + entry.totalPay, 0).toFixed(2)}`
    ];
    data.push(totals);

    const ws = XLSX.utils.aoa_to_sheet([...headerData, ...data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hot Sheet");
    XLSX.writeFile(wb, `hot_sheet_${dateStr}.xlsx`);
  };

  return (
    <div className="reports-container">
      <Sidebar />
      <div className="reports-content">
        <div className="tabs-container">
          <div className="tabs">
            <button 
              className={`tab-button ${activeTab === 'timeReports' ? 'active' : ''}`}
              onClick={() => handleTabChange('timeReports')}
            >
              Time Report
            </button>
            <button 
              className={`tab-button ${activeTab === 'vehicleReports' ? 'active' : ''}`}
              onClick={() => handleTabChange('vehicleReports')}
            >
              Vehicle Report
            </button>
            <button 
              className={`tab-button ${activeTab === 'hotSheet' ? 'active' : ''}`}
              onClick={() => handleTabChange('hotSheet')}
            >
              Hot Sheet
            </button>
          </div>
        </div>

        {/* Time Reports Tab */}
        {activeTab === 'timeReports' && (
          <div className="tab-content">
            <header className="page-header">
              <h1>Time Report</h1>
              <div className="date-navigation">
                <button onClick={handlePreviousDay}>&lt; Previous Day</button>
                <input
                  type="date"
                  value={selectedDate.toISOString().split('T')[0]}
                  onChange={handleDateChange}
                />
                <button onClick={handleNextDay}>Next Day &gt;</button>
              </div>
              <button onClick={handlePreviousDayReport} className="previous-day-report-btn">
                View Previous Day's Report
              </button>
              <p>Viewing entries for: {selectedDate.toDateString()}</p>
              <div className="report-header">
                <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
              </div>
            </header>
            <main>
              <div className="time-report-table">
                <div className="export-buttons">
                  <button onClick={exportToPDF}>Export to PDF</button>
                  <button onClick={exportToExcel}>Export to Excel</button>
                </div>
                <div className="column-selector">
                  <h3>Select Columns to Export:</h3>
                  <div className="column-options">
                    {columns.map(column => (
                      <label key={column.id} className="column-option">
                        <input
                          type="checkbox"
                          checked={selectedColumns[column.id]}
                          onChange={() => handleColumnToggle(column.id)}
                        />
                        {column.label}
                      </label>
                    ))}
                  </div>
                </div>
                {loading ? (
                  <p>Loading time entries...</p>
                ) : error ? (
                  <p className="error-message">{error}</p>
                ) : (
                  <>
                    <div className="report-header">
                      <p><strong>Department:</strong> {departmentMap[currentDepartment] || currentDepartment}</p>
                      <p><strong>Production:</strong> {currentProduction}</p>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Union</th>
                          <th>Call</th>
                          <th>Wrap</th>
                          <th>Total Hours</th>
                          <th>Rate</th>
                          <th>Vendor</th>
                          <th>Tow/Tag</th>
                          <th>Trailer/Tag</th>
                        </tr>
                      </thead>
                      <tbody>
                        {timeEntries.length === 0 ? (
                          <tr>
                            <td colSpan="9" className="no-entries-message">
                              No time entries found for the selected date.
                            </td>
                          </tr>
                        ) : (
                          timeEntries.map(entry => (
                            <tr key={entry.id}>
                              <td>{entry.name}</td>
                              <td>{entry.union}</td>
                              <td>{formatTime(entry.callTime)}</td>
                              <td>{formatTime(entry.wrapTime)}</td>
                              <td>{calculateTotalHours(entry)}</td>
                              <td>${typeof entry.rate === 'number' ? entry.rate.toFixed(2) : entry.rate}/hr</td>
                              <td>{entry.vendor || ''}</td>
                              <td>{`${entry.towTag || 'No unit'}/${entry.trailerTag || 'No plate'}`}</td>
                              <td>Not assigned</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </main>
          </div>
        )}

        {/* Vehicle Reports Tab */}
        {activeTab === 'vehicleReports' && (
          <div className="tab-content">
            <div className="vehicle-report-section">
              <header className="page-header">
                <h2>Vehicle Reports</h2>
                <div className="date-navigation">
                  <button onClick={handlePreviousWeekVehicle}>&lt; Previous Week</button>
                  <span className="week-display">
                    {formatWeekRange(vehicleReportDate)}
                  </span>
                  <button onClick={handleNextWeekVehicle}>Next Week &gt;</button>
                </div>
                <div className="report-header">
                  <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
                </div>
              </header>
              <div className="export-buttons">
                <button onClick={exportVehiclesToPDF}>Export to PDF</button>
                <button onClick={exportVehiclesToExcel}>Export to Excel</button>
              </div>
              <div className="report-header">
                <p><strong>Department:</strong> {departmentMap[user.department] || user.department}</p>
                <p><strong>Production:</strong> {user.production}</p>
              </div>
              <div className="vehicle-table-container">
                <table className="vehicle-table">
                  <thead>
                    <tr>
                      <th>Vendor</th>
                      <th>Unit Number</th>
                      <th>Tag #</th>
                      <th>Driver Assigned</th>
                      <th>Rental Rate</th>
                      <th>Rental Start</th>
                      <th>Rental End</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {vehicleEntries.map(entry => {
                      const daysCount = daysCounts[entry.id] || 1;
                      const dailyCost = calculateDailyCost(entry, daysCount);
                      // Convert rental rate to number and handle invalid values
                      const rate = Number(entry.rentalRate) || 0;

                      return (
                        <tr key={entry.id}>
                          <td>{entry.vendor}</td>
                          <td>{entry.unitNumber}</td>
                          <td>{entry.licensePlate}</td>
                          <td>{entry.driverAssigned}</td>
                          <td>
                            ${rate.toFixed(2)}/{entry.rentalPeriod}
                            {entry.rentalPeriod === 'daily' && (
                              <div className="daily-rate-calculator">
                                <input
                                  type="number"
                                  min="1"
                                  value={daysCount}
                                  onChange={(e) => handleDaysChange(entry.id, e.target.value)}
                                  className="days-input"
                                />
                              </div>
                            )}
                          </td>
                          <td>{entry.rentalPeriod === 'daily' ? daysCount : ''}</td>
                          <td>{dailyCost ? `$${dailyCost}` : 'N/A'}</td>
                          <td>{entry.rentalStart ? new Date(entry.rentalStart.seconds * 1000).toLocaleDateString() : 'Not set'}</td>
                          <td>{entry.rentalEnd ? new Date(entry.rentalEnd.seconds * 1000).toLocaleDateString() : 'Not set'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="6" className="total-label">Weekly Total:</td>
                      <td colSpan="2" className="total-amount">
                        ${vehicleEntries.reduce((total, vehicle) => {
                          const cost = calculateDailyCost(vehicle, 7); // Calculate for 7 days
                          return total + (cost ? parseFloat(cost) : 0);
                        }, 0).toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}

        {/* Hot Sheet Tab */}
        {activeTab === 'hotSheet' && (
          <div className="tab-content">
            <div className="hot-sheet-section">
              <header className="page-header">
                <h2>Hot Sheet</h2>
                <div className="date-navigation">
                  <button onClick={handlePreviousDayHotSheet}>&lt; Previous Day</button>
                  <input
                    type="date"
                    value={hotSheetDate.toISOString().split('T')[0]}
                    onChange={handleHotSheetDateChange}
                  />
                  <button onClick={handleNextDayHotSheet}>Next Day &gt;</button>
                </div>
                <p>Viewing hot sheet for: {hotSheetDate.toDateString()}</p>
                <div className="report-header">
                  <p>Showing entries for {departmentMap[user.department] || user.department} department in {user.production} production</p>
                </div>
              </header>
              <div className="export-buttons">
                <button onClick={exportHotSheetToPDF}>Export to PDF</button>
                <button onClick={exportHotSheetToExcel}>Export to Excel</button>
              </div>
              <div className="report-header">
                <p><strong>Department:</strong> {departmentMap[currentDepartment] || currentDepartment}</p>
                <p><strong>Production:</strong> {currentProduction}</p>
              </div>
              <div className="hot-sheet-table-container">
                <table className="hot-sheet-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Union</th>
                      <th>Call</th>
                      <th>Wrap</th>
                      <th>Total Hours</th>
                      <th>Rate</th>
                      <th>Regular Pay</th>
                      <th>Time and Half Pay</th>
                      <th>Double Time Pay</th>
                      <th>Triple Time Pay</th>
                      <th>Total Pay</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hotSheetEntries.map(entry => (
                      <tr key={entry.id}>
                        <td>{entry.name}</td>
                        <td>{entry.union}</td>
                        <td>{entry.call}</td>
                        <td>{entry.wrap}</td>
                        <td>{entry.totalHours.toFixed(2)}</td>
                        <td>${(parseFloat(entry.rate) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.regularPay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.timeAndHalfPay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.doubleTimePay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.tripleTimePay) || 0).toFixed(2)}</td>
                        <td>${(parseFloat(entry.totalPay) || 0).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colSpan="6" className="total-label">Daily Total:</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.regularPay, 0).toFixed(2)}</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.timeAndHalfPay, 0).toFixed(2)}</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.doubleTimePay, 0).toFixed(2)}</td>
                      <td>${hotSheetEntries.reduce((sum, entry) => sum + entry.tripleTimePay, 0).toFixed(2)}</td>
                      <td className="total-amount">${hotSheetEntries.reduce((sum, entry) => sum + entry.totalPay, 0).toFixed(2)}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </div>
  );
}

export default Reports;