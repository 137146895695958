import { doc, setDoc, getDoc, updateDoc, collection, getCountFromServer, query, where, and } from 'firebase/firestore';
import { db } from './firebase';

const logError = (functionName, error) => {
  console.error(`Error in ${functionName}:`, error);
  throw error;
};

export const createUserDocument = async (user, additionalData) => {
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);

  try {
    await setDoc(userRef, {
      email: user.email,
      ...additionalData
    }, { merge: true });
    console.log("User document created successfully");
  } catch (error) {
    console.error("Error creating user document:", error);
  }
};

export const getUserDocument = async (uid) => {
  if (!uid) return null;

  try {
    const userDocRef = doc(db, 'users', uid);
    const userDoc = await getDoc(userDocRef);

    return {
      uid,
      ...userDoc.data()
    };
  } catch (error) {
    console.error('Error fetching user', error);
  }
};

export const updateUserDocument = async (uid, data) => {
  if (!uid || !data) return;

  try {
    const userDocRef = doc(db, 'users', uid);
    await updateDoc(userDocRef, data);
  } catch (error) {
    console.error('Error updating user document', error);
  }
};

export const getTotalUserCount = async () => {
  try {
    const usersCollection = collection(db, 'users');
    const snapshot = await getCountFromServer(usersCollection);
    return snapshot.data().count;
  } catch (error) {
    logError('getTotalUserCount', error);
  }
};

export const getTotalDepartmentCount = async () => {
  const departmentsCollection = collection(db, 'departments');
  const snapshot = await getCountFromServer(departmentsCollection);
  return snapshot.data().count;
};

export const getWaitlistCount = async () => {
  const waitlistCollection = collection(db, 'waitlist');
  const snapshot = await getCountFromServer(waitlistCollection);
  return snapshot.data().count;
};

export const getTrialProductionsCount = async () => {
  const productionsCollection = collection(db, 'productions');
  const trialQuery = query(productionsCollection, where("status", "==", "trial"));
  const snapshot = await getCountFromServer(trialQuery);
  return snapshot.data().count;
};

export const getPaidProductionsCount = async () => {
  const productionsCollection = collection(db, 'productions');
  const paidQuery = query(productionsCollection, where("status", "==", "paid"));
  const snapshot = await getCountFromServer(paidQuery);
  return snapshot.data().count;
};

export const getActiveTrialProductionsCount = async () => {
  try {
    const productionsCollection = collection(db, 'productions');
    const activeTrialQuery = query(productionsCollection, 
      and(
        where("status", "==", "trial"),
        where("isActive", "==", true)
      )
    );
    const snapshot = await getCountFromServer(activeTrialQuery);
    return snapshot.data().count;
  } catch (error) {
    logError('getActiveTrialProductionsCount', error);
  }
};

export const getActivePaidProductionsCount = async () => {
  const productionsCollection = collection(db, 'productions');
  const activePaidQuery = query(productionsCollection, 
    and(
      where("status", "==", "paid"),
      where("isActive", "==", true)
    )
  );
  const snapshot = await getCountFromServer(activePaidQuery);
  return snapshot.data().count;
};