import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, addDoc, updateDoc, doc, deleteField, query, where, deleteDoc, getDoc, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuth } from '../hooks/useAuth';
import Sidebar from '../components/Sidebar';
import './Vehicles.css';
import '../styles/SharedStyles.css';
import { useLocation } from 'react-router-dom';
import Toast from '../components/Toast';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Timestamp } from 'firebase/firestore';

// Add this near the top of your file, after the imports
const stateAbbreviations = [
  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 
  'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 
  'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
];

// Add this function near your other utility functions
const uploadImage = async (image) => {
  if (!image) return null;
  
  try {
    const storage = getStorage();
    const storageRef = ref(storage, `vehicle-images/${Date.now()}-${image.name}`);
    
    const snapshot = await uploadBytes(storageRef, image);
    const downloadURL = await getDownloadURL(snapshot.ref);
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

function VehiclesPage() {
  const location = useLocation();
  const [vehicles, setVehicles] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddVendorForm, setShowAddVendorForm] = useState(false);
  const [toast, setToast] = useState(null);
  const [newVehicle, setNewVehicle] = useState({
    make: '',
    model: '',
    year: '',
    licensePlate: '',
    licensePlateState: '',
    unitNumber: '',
    status: 'available',
    imageUrl: '',
    vendorId: '',
    rentalRate: '',
    rentalPeriod: 'daily'
  });
  const [newVendor, setNewVendor] = useState({
    name: '',
    contact: '',
    phone: '',
    email: ''
  });
  const [images, setImages] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [crewMembers, setCrewMembers] = useState([]);
  const [productions, setProductions] = useState([]);
  const [selectedProduction, setSelectedProduction] = useState('');
  const [showAddVehicleForm, setShowAddVehicleForm] = useState(location.state?.showAddForm || false);
  const [documents, setDocuments] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [damageImages, setDamageImages] = useState([]);
  const [editingVehicle, setEditingVehicle] = useState(null);
  const [editForm, setEditForm] = useState({
    licensePlate: '',
    unitNumber: '',
    vendorId: '',
    rentalRate: '',
    rentalPeriod: 'daily',
    production: '',
    newImages: [],
    newDocuments: [],
    imagesToDelete: [],
    documentsToDelete: [],
    damageImagesToDelete: []
  });

  const isCrewMember = (role) => role === 'crewMember' || role === 'Crew Member';
  const isSupervisor = (role) => role === 'supervisor' || role === 'Supervisor';
  const isAdmin = (role) => role === 'admin' || role === 'Admin';
  const isProductionAdmin = (role) => role === 'productionAdmin' || role === 'Production Admin';

  useEffect(() => {
    if (user && !isCrewMember(user.role)) {
      fetchVehicles();
      fetchVendors();
      fetchCrewMembers();
      fetchProductions();
    }
  }, [user]);

  const fetchVehicles = async () => {
    try {
      setIsLoading(true);
      const db = getFirestore();
      
      console.log('Fetching vehicles for user:', {
        role: user.role,
        department: user.department,
        production: user.production
      });

      const vehiclesQuery = collection(db, 'vehicles');
      const querySnapshot = await getDocs(vehiclesQuery);
      const fetchedVehicles = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Fetched vehicles:', fetchedVehicles);
      setVehicles(fetchedVehicles);

    } catch (error) {
      console.error('Error fetching vehicles:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchVendors = async () => {
    try {
      const db = getFirestore();
      const vendorsCollection = collection(db, 'vendors');
      const querySnapshot = await getDocs(vendorsCollection);
      const vendorsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVendors(vendorsData);
    } catch (error) {
      console.error('Error fetching vendors:', error);
      setError(error.message);
    }
  };

  const fetchCrewMembers = async () => {
    try {
      const db = getFirestore();
      const crewMembersCollection = collection(db, 'users');
      console.log("Attempting to fetch crew members. User role:", user.role);
      const querySnapshot = await getDocs(crewMembersCollection);
      const crewMembersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setCrewMembers(crewMembersData);
      console.log("Successfully fetched crew members. Count:", crewMembersData.length);
    } catch (error) {
      console.error('Detailed error fetching crew members:', error);
      let errorMessage = "Error fetching crew members: ";
      if (error.code === 'permission-denied') {
        errorMessage += "Permission denied. Your role may not have access to this data.";
      } else if (error.code === 'unauthenticated') {
        errorMessage += "User is not authenticated. Please log in again.";
      } else {
        errorMessage += error.message || "Unknown error occurred";
      }
      errorMessage += ` (User role: ${user.role}, Error code: ${error.code})`;
      setError(errorMessage);
    }
  };

  const fetchProductions = async () => {
    try {
      const db = getFirestore();
      const productionsCollection = collection(db, 'productions');
      const querySnapshot = await getDocs(productionsCollection);
      const productionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProductions(productionsData);
    } catch (error) {
      console.error('Error fetching productions:', error);
      setError(error.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewVehicle({ ...newVehicle, [name]: value });
  };

  const handleVendorInputChange = (e) => {
    const { name, value } = e.target;
    setNewVendor({ ...newVendor, [name]: value });
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(prevImages => [...prevImages, ...files]);
  };

  const handleDocumentChange = (e) => {
    const files = Array.from(e.target.files);
    setNewDocuments(prevDocuments => [...prevDocuments, ...files]);
  };

  const handleDamageImageChange = (e) => {
    const files = Array.from(e.target.files);
    setDamageImages(prevImages => [...prevImages, ...files]);
  };

  const handleAddVehicle = async (e) => {
    e.preventDefault();
    if (!user) return;

    try {
      const db = getFirestore();
      const vehicleData = {
        ...newVehicle,
        department: 'Transportation',
        production: 'the big test show',
        status: 'available',
        createdAt: serverTimestamp()
      };

      console.log('Creating new vehicle:', vehicleData);

      const docRef = await addDoc(collection(db, 'vehicles'), vehicleData);
      
      // Handle image uploads if any
      if (images.length > 0) {
        const imageUrls = await Promise.all(images.map(uploadImage));
        await updateDoc(doc(db, 'vehicles', docRef.id), {
          imageUrls
        });
      }

      setShowAddVehicleForm(false);
      setNewVehicle({
        make: '',
        model: '',
        year: '',
        licensePlate: '',
        licensePlateState: '',
        unitNumber: '',
        status: 'available',
        imageUrl: '',
        vendorId: '',
        rentalRate: '',
        rentalPeriod: 'daily'
      });
      setImages([]);
      fetchVehicles();
      setToast({
        message: 'Vehicle added successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error adding vehicle:', error);
      setError(error.message);
    }
  };

  const handleAddVendor = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      await addDoc(collection(db, 'vendors'), newVendor);
      setNewVendor({
        name: '',
        contact: '',
        phone: '',
        email: ''
      });
      fetchVendors();
      setShowAddVendorForm(false);
    } catch (error) {
      console.error("Error adding vendor: ", error);
      setError(error.message);
    }
  };

  const handleUpdateVehicle = async (e) => {
    e.preventDefault();
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', selectedVehicle.id);
      
      let updatedData = { ...selectedVehicle };
      
      if (images.length > 0 || newDocuments.length > 0) {
        const storage = getStorage();
        let newImageUrls = [];
        let newDocumentUrls = [];

        for (let image of images) {
          const imageRef = ref(storage, `vehicles/${selectedVehicle.id}/${image.name}`);
          await uploadBytes(imageRef, image);
          const url = await getDownloadURL(imageRef);
          newImageUrls.push(url);
        }

        for (let document of newDocuments) {
          const documentRef = ref(storage, `vehicle_documents/${selectedVehicle.id}/${document.name}`);
          await uploadBytes(documentRef, document);
          const url = await getDownloadURL(documentRef);
          newDocumentUrls.push(url);
        }

        updatedData.imageUrls = [...(updatedData.imageUrls || []), ...newImageUrls];
        updatedData.documentUrls = [...(updatedData.documentUrls || []), ...newDocumentUrls];
      }

      await updateDoc(vehicleRef, updatedData);
      setSelectedVehicle(null);
      setIsEditing(false);
      setImages([]);
      setNewDocuments([]);
      fetchVehicles();
    } catch (error) {
      console.error("Error updating vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveImage = async (vehicleId, imageUrl, imageIndex) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      const updatedImageUrls = vehicleDoc.data().imageUrls.filter((_, index) => index !== imageIndex);
      await updateDoc(vehicleRef, { imageUrls: updatedImageUrls });

      fetchVehicles();
    } catch (error) {
      console.error("Error removing image: ", error);
      setError(error.message);
    }
  };

  const handleAssignVehicle = async (vehicleId, crewMemberId, productionId) => {
    if (!(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role))) {
      setError("You don't have permission to assign vehicles.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: crewMemberId,
        productionId: productionId,
        status: 'in_use'
      });
      fetchVehicles();
    } catch (error) {
      console.error("Error assigning vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveFromProduction = async (vehicleId) => {
    if (!(isSupervisor(user.role) || isProductionAdmin(user.role) || isAdmin(user.role))) {
      setError("Only supervisors, production admins, or admins can remove vehicles from production.");
      return;
    }
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        assignedTo: '',
        productionId: '',
        status: 'available'
      });
      fetchVehicles();
    } catch (error) {
      console.error("Error removing vehicle from production: ", error);
      setError(error.message);
    }
  };

  const handleAttachToProduction = async () => {
    if (!selectedVehicle || !selectedProduction) {
      setError('Please select both a vehicle and a production');
      return;
    }

    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', selectedVehicle.id);

      // Get the production details
      const productionDoc = await getDoc(doc(db, 'productions', selectedProduction));
      const productionName = productionDoc.data().name;

      console.log('Attaching vehicle to production:', {
        vehicleId: selectedVehicle.id,
        productionId: selectedProduction,
        productionName: productionName
      });

      // Update the vehicle with both productionId and production name
      await updateDoc(vehicleRef, {
        productionId: selectedProduction,
        production: productionName
      });

      setToast({
        message: 'Vehicle attached to production successfully',
        type: 'success'
      });

      // Reset selections and refresh the list
      setSelectedVehicle(null);
      setSelectedProduction('');
      fetchVehicles();

    } catch (error) {
      console.error('Error attaching vehicle to production:', error);
      setError(error.message);
    }
  };

  const handleRemoveVehicle = async (vehicleId) => {
    if (!(isAdmin(user.role) || isSupervisor(user.role))) {
      setError("Only admins and supervisors can remove vehicles.");
      return;
    }
    try {
      const db = getFirestore();
      await deleteDoc(doc(db, 'vehicles', vehicleId));
      fetchVehicles();
    } catch (error) {
      console.error("Error removing vehicle: ", error);
      setError(error.message);
    }
  };

  const handleRemoveDocument = async (vehicleId, documentUrl, documentIndex) => {
    try {
      const storage = getStorage();
      const documentRef = ref(storage, documentUrl);
      await deleteObject(documentRef);

      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);
      const vehicleDoc = await getDoc(vehicleRef);
      const updatedDocumentUrls = vehicleDoc.data().documentUrls.filter((_, index) => index !== documentIndex);
      await updateDoc(vehicleRef, { documentUrls: updatedDocumentUrls });

      fetchVehicles();
    } catch (error) {
      console.error("Error removing document: ", error);
      setError(error.message);
    }
  };

  const handleVendorAssignment = async (vehicleId, vendorId) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        vendorId: vendorId
      });
      
      // Update local state
      setVehicles(vehicles.map(vehicle => 
        vehicle.id === vehicleId 
          ? { ...vehicle, vendorId: vendorId }
          : vehicle
      ));
      
      setToast({ 
        message: 'Vendor assigned successfully', 
        type: 'success' 
      });
    } catch (error) {
      console.error('Error assigning vendor:', error);
      setToast({ 
        message: 'Failed to assign vendor', 
        type: 'error' 
      });
    }
  };

  const handleRentalDateChange = async (vehicleId, field, date) => {
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', vehicleId);

      // Convert date to Firestore Timestamp
      const timestamp = date ? Timestamp.fromDate(date) : null;

      await updateDoc(vehicleRef, {
        [field]: timestamp
      });

      // Update local state
      setVehicles(vehicles.map(vehicle => 
        vehicle.id === vehicleId 
          ? { ...vehicle, [field]: timestamp }
          : vehicle
      ));

      setToast({
        message: 'Rental date updated successfully',
        type: 'success'
      });

    } catch (error) {
      console.error('Error updating rental date:', error);
      setToast({
        message: 'Failed to update rental date',
        type: 'error'
      });
    }
  };

  const handleEditClick = (vehicle) => {
    setEditingVehicle(vehicle);
    setEditForm({
      licensePlate: vehicle.licensePlate || '',
      unitNumber: vehicle.unitNumber || '',
      vendorId: vehicle.vendorId || '',
      rentalRate: vehicle.rentalRate || '',
      rentalPeriod: vehicle.rentalPeriod || 'daily',
      production: vehicle.production || '',
      newImages: [],
      newDocuments: [],
      imagesToDelete: [],
      documentsToDelete: [],
      damageImagesToDelete: []
    });
  };

  const handleEditFormChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setEditForm(prev => ({
        ...prev,
        [name]: files ? Array.from(files) : []
      }));
    } else {
      setEditForm(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleEditSubmit = async () => {
    try {
      const db = getFirestore();
      const vehicleRef = doc(db, 'vehicles', editingVehicle.id);
      
      const updatedData = {
        licensePlate: editForm.licensePlate,
        unitNumber: editForm.unitNumber,
        vendorId: editForm.vendorId,
        rentalRate: editForm.rentalRate,
        rentalPeriod: editForm.rentalPeriod,
        production: editForm.production || 'the big test show',
        department: 'Transportation',
        // ... other fields ...
      };

      console.log('Updating vehicle with:', updatedData);
      
      await updateDoc(vehicleRef, updatedData);

      setToast({
        message: 'Vehicle updated successfully',
        type: 'success'
      });
      
      setEditingVehicle(null);
      fetchVehicles();
    } catch (error) {
      console.error('Error updating vehicle:', error);
      setError(error.message);
    }
  };

  const handleRentalRateChange = async (vehicleId, rate, period) => {
    try {
      const db = getFirestore();
      await updateDoc(doc(db, 'vehicles', vehicleId), {
        rentalRate: rate,
        rentalPeriod: period
      });

      // Update local state
      setVehicles(vehicles.map(vehicle => 
        vehicle.id === vehicleId 
          ? { ...vehicle, rentalRate: rate, rentalPeriod: period }
          : vehicle
      ));

      setToast({
        message: 'Rental rate updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating rental rate:', error);
      setToast({
        message: 'Failed to update rental rate',
        type: 'error'
      });
    }
  };

  // Add this function to VehiclesPage.js
  const updateAllVehiclesWithDepartment = async () => {
    try {
      const db = getFirestore();
      
      // Get all vehicles without department field
      const vehiclesQuery = query(
        collection(db, 'vehicles'),
        where('production', '==', 'the big test show')
      );
      
      const querySnapshot = await getDocs(vehiclesQuery);
      
      // Update each vehicle
      const updatePromises = querySnapshot.docs.map(async (doc) => {
        const vehicleRef = doc.ref;
        await updateDoc(vehicleRef, {
          department: 'Transportation'
        });
        console.log(`Updated vehicle ${doc.id} with department`);
      });
      
      await Promise.all(updatePromises);
      console.log('All vehicles updated with department field');
      
      // Refresh the vehicles list
      fetchVehicles();
      
    } catch (error) {
      console.error('Error updating vehicles with department:', error);
    }
  };

  // Filter vehicles based on the user's production
  const vehiclesInProduction = vehicles.filter(vehicle => vehicle.productionId === user.production);
  const availableVehicles = vehicles.filter(vehicle => !vehicle.productionId || vehicle.productionId !== user.production);

  if (isLoading) {
    return <div className="loading">Loading vehicles...</div>;
  }

  if (error) {
    return <div className="error-message">Error: {error}</div>;
  }

  return (
    <div className="vehicles-container">
      <Sidebar />
      <div className="vehicles-content">
        <header className="page-header">
          <h1>Production Vehicles</h1>
          <p>Manage your productions vehicles</p>
        </header>
        <main>
          <button className="add-vehicle-btn" onClick={() => setShowAddVehicleForm(true)}>
            Add New Vehicle
          </button>
          {showAddVehicleForm && (
            <section className="add-vehicle">
              <h2>Add New Vehicle</h2>
              <form onSubmit={handleAddVehicle}>
                <div className="form-group">
                  <label htmlFor="make">Make:</label>
                  <input
                    type="text"
                    id="make"
                    name="make"
                    value={newVehicle.make}
                    onChange={handleInputChange}
                    placeholder="Make"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="model">Model:</label>
                  <input
                    type="text"
                    id="model"
                    name="model"
                    value={newVehicle.model}
                    onChange={handleInputChange}
                    placeholder="Model"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="year">Year:</label>
                  <input
                    type="number"
                    id="year"
                    name="year"
                    value={newVehicle.year}
                    onChange={handleInputChange}
                    placeholder="Year"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="licensePlate">License Plate:</label>
                  <input
                    type="text"
                    id="licensePlate"
                    name="licensePlate"
                    value={newVehicle.licensePlate}
                    onChange={handleInputChange}
                    placeholder="License Plate"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="licensePlateState">License Plate State:</label>
                  <select
                    id="licensePlateState"
                    name="licensePlateState"
                    value={newVehicle.licensePlateState}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select State</option>
                    {stateAbbreviations.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="unitNumber">Unit Number:</label>
                  <input
                    type="text"
                    id="unitNumber"
                    name="unitNumber"
                    value={newVehicle.unitNumber}
                    onChange={handleInputChange}
                    placeholder="Unit Number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vendorId">Vendor:</label>
                  <select
                    id="vendorId"
                    name="vendorId"
                    value={newVehicle.vendorId}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select Vendor</option>
                    {vendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>{vendor.name}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="vehicleImage">Vehicle Pictures:</label>
                  <input
                    type="file"
                    id="vehicleImage"
                    onChange={handleImageChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="damageImages">Damage Pictures:</label>
                  <input
                    type="file"
                    id="damageImages"
                    onChange={handleDamageImageChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="vehicleDocuments">Document Pictures:</label>
                  <input
                    type="file"
                    id="vehicleDocuments"
                    onChange={handleDocumentChange}
                    accept="image/*"
                    multiple
                  />
                </div>
                <button type="submit">Add Vehicle</button>
                <button type="button" onClick={() => setShowAddVehicleForm(false)}>Cancel</button>
              </form>
            </section>
          )}
          <section className="vehicles-list">
            <h2>Vehicles in Current Production</h2>
            <ul>
              {vehiclesInProduction.map(vehicle => (
                <li key={vehicle.id}>
                  <h3>{vehicle.make} {vehicle.model} ({vehicle.year})</h3>
                  <p>Unit Number: {vehicle.unitNumber}</p>
                  <p>License Plate: {vehicle.licensePlate}</p>
                  {vehicle.vendorId && (
                    <p>Vendor: {vendors.find(v => v.id === vehicle.vendorId)?.name || 'Unknown'}</p>
                  )}
                  {vehicle.rentalRate && (
                    <p>Rental Rate: ${vehicle.rentalRate}/{vehicle.rentalPeriod}</p>
                  )}
                </li>
              ))}
              {vehiclesInProduction.length === 0 && (
                <div className="no-vehicles-message">
                  No vehicles assigned to this production
                </div>
              )}
            </ul>
          </section>

          <section className="unassigned-vehicles">
            <h2>Available Vehicles</h2>
            <div className="unassigned-vehicles-list">
              {availableVehicles.map(vehicle => (
                <div key={vehicle.id} className="unassigned-vehicle-card">
                  <div className="vehicle-info">
                    <h3>{vehicle.make} {vehicle.model} ({vehicle.year})</h3>
                    <p>Unit Number: {vehicle.unitNumber || 'N/A'}</p>
                    <p>License Plate: {vehicle.licensePlate}</p>
                    {vehicle.vendorId && (
                      <p>Vendor: {vendors.find(v => v.id === vehicle.vendorId)?.name || 'Unknown'}</p>
                    )}
                    {vehicle.rentalRate && (
                      <p>Rental Rate: ${vehicle.rentalRate}/{vehicle.rentalPeriod}</p>
                    )}
                  </div>
                  <div className="vehicle-actions">
                    <button 
                      onClick={() => {
                        setSelectedVehicle(vehicle);
                        document.querySelector('.attach-controls').scrollIntoView({ 
                          behavior: 'smooth' 
                        });
                      }}
                      className="select-vehicle-btn"
                    >
                      Select for Production
                    </button>
                  </div>
                </div>
              ))}
              {availableVehicles.length === 0 && (
                <div className="no-vehicles-message">
                  No unassigned vehicles available
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
      {error && <div className="error-message">Error: {error}</div>}
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
      {isAdmin(user.role) && (
        <button 
          onClick={updateAllVehiclesWithDepartment}
          style={{ marginBottom: '20px' }}
        >
          Update All Vehicles with Department
        </button>
      )}
    </div>
  );
}

export default VehiclesPage;