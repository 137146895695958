import React, { useState, useEffect, useContext, useCallback } from 'react';
import { collection, query, where, getDocs, orderBy, limit, Timestamp, getDoc, doc, updateDoc, serverTimestamp, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import './Notifications.css';
import '../styles/SharedStyles.css';
import Toast from '../components/Toast';
import { debounce } from 'lodash';

// Move formatDate outside of NotificationItem to be accessible by both components
const formatDate = (timestamp) => {
  if (!timestamp || !timestamp.toDate) return 'No date';
  const date = timestamp.toDate();
  return date.toLocaleDateString();
};

const NotificationItem = React.memo(({ notification, onAcknowledge, isSentNotification }) => {
  const [recipientName, setRecipientName] = useState('Unknown recipient');
  const [senderName, setSenderName] = useState('Unknown sender');
  const [acknowledged, setAcknowledged] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(notification.status || 'Pending');

  useEffect(() => {
    const fetchNames = async () => {
      // Fetch recipient name for sent notifications
      const recipientId = notification.userId || notification.assignedTo;
      if ((notification.type === 'sent' || 
           notification.type === 'sentDispatchedTime' || 
           notification.type === 'sentDispatchedRun') && recipientId) {
        try {
          const userDoc = await getDoc(doc(db, 'users', recipientId));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            const fullName = `${userData.firstName || ''} ${userData.lastName || ''}`.trim();
            setRecipientName(fullName || userData.email || 'Unknown recipient');
          }
        } catch (error) {
          console.error('Error fetching recipient name:', error);
        }
      }

      // Fetch sender name for received notifications
      const senderId = notification.senderId || notification.createdBy;
      if ((notification.type === 'received' || 
           notification.type === 'dispatchedTime' || 
           notification.type === 'dispatchedRun') && senderId) {
        try {
          const senderDoc = await getDoc(doc(db, 'users', senderId));
          if (senderDoc.exists()) {
            const senderData = senderDoc.data();
            const fullName = `${senderData.firstName || ''} ${senderData.lastName || ''}`.trim();
            setSenderName(fullName || senderData.email || 'Unknown sender');
          }
        } catch (error) {
          console.error('Error fetching sender name:', error);
        }
      }
    };

    fetchNames();
  }, [notification]);

  // Update currentStatus when notification changes
  useEffect(() => {
    setCurrentStatus(notification.status || 'Pending');
  }, [notification.status]);

  const formatTimestamp = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      return timestamp.toDate().toLocaleString();
    }
    return 'Unknown date';
  };

  const handleStatusChange = async (newStatus) => {
    try {
      await updateDoc(doc(db, 'dispatchedRuns', notification.id), {
        status: newStatus,
        updatedAt: serverTimestamp()
      });
      setCurrentStatus(newStatus);
      setToast({ 
        message: `Status updated to ${newStatus}`, 
        type: 'success'  // Green toast for success
      });
    } catch (error) {
      console.error('Error updating status:', error);
      setToast({ 
        message: `Failed to update status: ${error.message}`, 
        type: 'error'  // Red toast for error
      });
      // Revert to previous status if update fails
      setCurrentStatus(notification.status);
    }
  };

  const handleAcknowledge = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    setError(null);

    try {
      let docRef;
      
      // Determine which collection to update based on notification type
      if (notification.type.includes('Run')) {
        docRef = doc(db, 'dispatchedRuns', notification.id);
      } else if (notification.type.includes('Time')) {
        docRef = doc(db, 'timeEntries', notification.id);
      } else {
        docRef = doc(db, 'notifications', notification.id);
      }

      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        throw new Error(`${notification.type} document not found`);
      }

      await updateDoc(docRef, {
        acknowledged: true,
        status: 'Acknowledged',
        acknowledgedAt: serverTimestamp()
      });

      setAcknowledged(true);
      setCurrentStatus('Acknowledged');
      setToast({ 
        message: `${notification.type} acknowledged successfully`, 
        type: 'success' 
      });
      
      if (onAcknowledge) {
        onAcknowledge(notification.id);
      }
    } catch (error) {
      console.error('Error acknowledging notification:', error);
      setToast({ 
        message: `Failed to acknowledge: ${error.message}`, 
        type: 'error' 
      });
    } finally {
      setIsProcessing(false);
    }
  };

  // Add error message display
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(null);
      }, 5000); // Clear error after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [error]);

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) return 'N/A';
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <>
      <tr 
        className={`notification-row ${notification.type} ${acknowledged ? 'acknowledged' : ''}`}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      >
        <td>{notification.type}</td>
        <td>
          {notification.type.includes('Time') ? 
            `Call Time: ${formatTime(notification.startTime)}` : 
            notification.title || 'No title'
          }
        </td>
        <td>{notification.type.includes('sent') ? recipientName : senderName}</td>
        <td>{formatDate(notification.date)}</td>
        <td>
          {isSentNotification ? (
            notification.status || 'Pending'
          ) : (
            notification.acknowledged || acknowledged ? (
              <select
                value={currentStatus}
                onChange={(e) => handleStatusChange(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className="status-select"
              >
                <option value="Acknowledged">Acknowledged</option>
                <option value="In Route">In Route</option>
                <option value="Picked Up">Picked Up</option>
                <option value="Dropped Off">Dropped Off</option>
              </select>
            ) : (
              <button 
                onClick={(e) => {
                  e.stopPropagation();
                  handleAcknowledge();
                }}
                className={`acknowledge-button ${acknowledged ? 'acknowledged' : ''}`}
                disabled={isProcessing}
              >
                {isProcessing ? 'Processing...' : 'Acknowledge'}
              </button>
            )
          )}
        </td>
      </tr>
      {isExpanded && (
        <tr className="expanded-notification">
          <td colSpan="5">
            <div className="notification-details">
              {notification.type.includes('Time') && (
                <>
                  <p><strong>Call Time:</strong> {formatTime(notification.startTime)}</p>
                  <p><strong>Wrap Time:</strong> {formatTime(notification.endTime)}</p>
                  <p><strong>Date:</strong> {formatDate(notification.date)}</p>
                </>
              )}
              {notification.type.includes('Run') && (
                <>
                  <p><strong>Run Type:</strong> {notification.runType}</p>
                  <p><strong>Address:</strong> {notification.address}</p>
                  <p><strong>Description:</strong> {notification.itemDescription}</p>
                  <p><strong>Status:</strong> {notification.status}</p>
                  {notification.pictureUrl && (
                    <div className="notification-image">
                      <img src={notification.pictureUrl} alt="Run" />
                    </div>
                  )}
                </>
              )}
              {notification.message && (
                <p><strong>Message:</strong> {notification.message}</p>
              )}
            </div>
          </td>
        </tr>
      )}
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </>
  );
});

function Notifications() {
  const { user } = useContext(AuthContext);
  const [receivedNotifications, setReceivedNotifications] = useState([]);
  const [sentNotifications, setSentNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredNotifications, setFilteredNotifications] = useState({
    received: [],
    sent: []
  });
  const [showAllNotifications, setShowAllNotifications] = useState(true);
  const [notificationType, setNotificationType] = useState('all');
  const [toast, setToast] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState({});
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    if (user) {
      fetchNotifications();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      setPage(1);
      fetchNotifications();
    }
  }, [user, notificationType]);

  const refreshNotifications = () => {
    setPage(1);
    setHasMore(true);
    fetchNotifications();
  };

  const handleDateFilter = () => {
    if (!dateRange.startDate || !dateRange.endDate) {
      setToast({ message: 'Please select both start and end dates', type: 'error' });
      return;
    }

    try {
      const startParts = dateRange.startDate.split('-');
      const endParts = dateRange.endDate.split('-');
      const startDateStr = `${parseInt(startParts[1])}/${parseInt(startParts[2])}/${startParts[0]}`;
      const endDateStr = `${parseInt(endParts[1])}/${parseInt(endParts[2])}/${endParts[0]}`;

      const dateFilteredResults = {
        received: receivedNotifications.filter(notification => {
          const displayedDate = formatDate(notification.date);
          return displayedDate >= startDateStr && displayedDate <= endDateStr;
        }),

        sent: sentNotifications.filter(notification => {
          const displayedDate = formatDate(notification.date);
          return displayedDate >= startDateStr && displayedDate <= endDateStr;
        })
      };

      setFilteredNotifications(dateFilteredResults);
      setShowAllNotifications(false);
    } catch (error) {
      console.error('Error applying date filter:', error);
      setToast({ message: 'Error applying date filter', type: 'error' });
    }
  };

  const clearDateFilter = () => {
    setStartDate('');
    setEndDate('');
    setNotificationType('all');
    setSearchTerm('');
    setFilteredNotifications({ received: [], sent: [] });
    setShowAllNotifications(true);
    setToast({ message: 'Filters cleared', type: 'success' });
  };

  const handleShowAll = () => {
    setShowAllNotifications(true);
    setDateRange({ startDate: '', endDate: '' });
    setNotificationType('all');
    setSearchTerm('');
    setFilteredNotifications({ received: [], sent: [] });
    fetchNotifications();
  };

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const pageSize = 20;
      let startTimestamp = null;
      let endTimestamp = null;

      if (!showAllNotifications && startDate) {
        startTimestamp = Timestamp.fromDate(new Date(startDate));
      }
      if (!showAllNotifications && endDate) {
        const endDateObj = new Date(endDate);
        endDateObj.setDate(endDateObj.getDate() + 1);
        endTimestamp = Timestamp.fromDate(endDateObj);
      }

      // Create separate arrays for each query's constraints
      let receivedConstraints = [
        where('userId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        limit(pageSize * page)
      ];

      if (startTimestamp) {
        receivedConstraints.unshift(where('timestamp', '>=', startTimestamp));
      }
      if (endTimestamp) {
        receivedConstraints.unshift(where('timestamp', '<', endTimestamp));
      }

      // Create the received notifications query
      const receivedNotificationsQuery = query(
        collection(db, 'notifications'),
        ...receivedConstraints
      );

      // Create the sent notifications query
      const sentNotificationsQuery = query(
        collection(db, 'notifications'),
        where('senderId', '==', user.uid),
        orderBy('timestamp', 'desc'),
        limit(pageSize * page)
      );

      // Create the dispatched times query
      const dispatchedTimesQuery = query(
        collection(db, 'timeEntries'),
        where('createdAt', '>', Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))),
        orderBy('createdAt', 'desc')
      );

      // Create the dispatched runs query
      const dispatchedRunsQuery = query(
        collection(db, 'dispatchedRuns'),
        where('createdAt', '>', Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000))),
        orderBy('createdAt', 'desc')
      );

      const receivedNotificationsSnapshot = await getDocs(receivedNotificationsQuery);
      console.log('Received notifications fetched:', receivedNotificationsSnapshot.size);

      const receivedNotificationsData = receivedNotificationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        type: 'received'
      }));

      const sentNotificationsSnapshot = await getDocs(sentNotificationsQuery);
      console.log('Sent notifications fetched:', sentNotificationsSnapshot.size);

      const sentNotificationsData = sentNotificationsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        type: 'sent'
      }));

      const dispatchedTimesSnapshot = await getDocs(dispatchedTimesQuery);
      console.log('Dispatched times fetched:', dispatchedTimesSnapshot.size);

      const dispatchedTimesData = dispatchedTimesSnapshot.docs
        .filter(doc => doc.data().userId === user.uid || doc.data().createdBy === user.uid)
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: doc.data().userId === user.uid ? 'dispatchedTime' : 'sentDispatchedTime'
        }));

      const dispatchedRunsSnapshot = await getDocs(dispatchedRunsQuery);
      console.log('Dispatched runs fetched:', dispatchedRunsSnapshot.size);

      const dispatchedRunsData = dispatchedRunsSnapshot.docs
        .filter(doc => doc.data().assignedTo === user.uid || doc.data().createdBy === user.uid)
        .map(doc => ({
          id: doc.id,
          ...doc.data(),
          type: doc.data().assignedTo === user.uid ? 'dispatchedRun' : 'sentDispatchedRun'
        }));

      // Update the state setting to handle pagination
      if (receivedNotificationsData.length < pageSize * page) {
        setHasMore(false);
      }

      const allReceivedNotifications = [
        ...(notificationType === 'all' || notificationType === 'times' 
          ? dispatchedTimesData.filter(n => n.type === 'dispatchedTime') 
          : []),
        ...(notificationType === 'all' || notificationType === 'runs' 
          ? dispatchedRunsData.filter(n => n.type === 'dispatchedRun') 
          : []),
        ...(notificationType === 'all' 
          ? receivedNotificationsData 
          : [])
      ];

      const allSentNotifications = [
        ...(notificationType === 'all' || notificationType === 'times' 
          ? dispatchedTimesData.filter(n => n.type === 'sentDispatchedTime') 
          : []),
        ...(notificationType === 'all' || notificationType === 'runs' 
          ? dispatchedRunsData.filter(n => n.type === 'sentDispatchedRun') 
          : []),
        ...(notificationType === 'all' 
          ? sentNotificationsData 
          : [])
      ];

      setReceivedNotifications(prevNotifications => 
        page === 1 ? allReceivedNotifications : [...prevNotifications, ...allReceivedNotifications]
      );
      setSentNotifications(prevNotifications => 
        page === 1 ? allSentNotifications : [...prevNotifications, ...allSentNotifications]
      );

      setLoading(false);
    } catch (error) {
      console.error('Detailed error fetching notifications:', error);
      setError(`Failed to fetch notifications: ${error.message}. Please try again.`);
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage(prevPage => prevPage + 1);
    fetchNotifications();
  };

  const handleAcknowledge = (notificationId, createdBy, userId, timestamp) => {
    // Update received notifications
    setReceivedNotifications(prevNotifications =>
      prevNotifications.map(notif =>
        notif.id === notificationId ? { ...notif, acknowledged: true } : notif
      )
    );

    // Update sent notifications - match by createdBy and userId
    setSentNotifications(prevNotifications =>
      prevNotifications.map(notif => {
        // Check if this is the matching sent notification
        const isMatch = 
          notif.createdBy === createdBy && 
          notif.userId === userId && 
          notif.date?.seconds === timestamp?.seconds;

        console.log('Checking sent notification:', {
          notifCreatedBy: notif.createdBy,
          createdBy,
          notifUserId: notif.userId,
          userId,
          notifTimestamp: notif.date?.seconds,
          timestamp: timestamp?.seconds,
          isMatch
        });

        return isMatch ? { ...notif, acknowledged: true } : notif;
      })
    );
  };

  const handleNotificationTypeChange = (type) => {
    console.log('Changing notification type to:', type);
    setNotificationType(type);

    // Filter notifications based on type
    const filteredReceived = receivedNotifications.filter(notification => {
      if (type === 'all') return true;
      if (type === 'times') return notification.type.toLowerCase().includes('time');
      if (type === 'runs') return notification.type.toLowerCase().includes('run');
      return false;
    });

    const filteredSent = sentNotifications.filter(notification => {
      if (type === 'all') return true;
      if (type === 'times') return notification.type.toLowerCase().includes('time');
      if (type === 'runs') return notification.type.toLowerCase().includes('run');
      return false;
    });

    console.log('Filtered results:', {
      received: filteredReceived.length,
      sent: filteredSent.length
    });

    setFilteredNotifications({
      received: filteredReceived,
      sent: filteredSent
    });
  };

  const getUserName = async (userId) => {
    if (!userId) return '';
    
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return `${userData.firstName} ${userData.lastName}` || '';
      }
      return '';
    } catch (error) {
      console.error('Error getting user name:', error);
      return '';
    }
  };

  // Helper function to get user info - moved outside handleSearch
  const getUserInfo = async (userId) => {
    if (!userId) return null;
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        return {
          name: `${userData.firstName || ''} ${userData.lastName || ''}`.trim(),
          email: userData.email
        };
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
    return null;
  };

  const handleSearch = async (event) => {
    const searchValue = event.target.value || '';
    console.log('Searching for:', searchValue);
    setSearchTerm(searchValue);

    const searchLower = searchValue.toLowerCase().trim();

    if (!searchLower) {
      setFilteredNotifications({
        received: receivedNotifications,
        sent: sentNotifications
      });
      return;
    }

    // Filter notifications
    const filterNotifications = async () => {
      console.log('Filtering notifications with search term:', searchLower);
      console.log('Total sent notifications:', sentNotifications.length);

      const processedIds = new Set();
      const matchedNotifications = [];
      
      for (const notification of sentNotifications) {
        // Skip if we've already processed this notification
        if (processedIds.has(notification.id)) continue;
        processedIds.add(notification.id);

        const userId = notification.type === 'sentDispatchedRun' ? 
          notification.assignedTo : notification.userId;
        
        const userInfo = await getUserInfo(userId);
        
        // Check name match
        const nameMatch = userInfo?.name?.toLowerCase().includes(searchLower);
        
        // Check details match
        let detailsMatch = false;
        if (notification.type === 'sentDispatchedRun') {
          const details = [
            notification.title || '',
            notification.itemDescription || '',
            notification.address || ''
          ].join(' ').toLowerCase();
          detailsMatch = details.includes(searchLower);
        }

        if (nameMatch || detailsMatch) {
          console.log('Found match:', {
            id: notification.id,
            name: userInfo?.name,
            type: notification.type,
            matchType: nameMatch ? 'name' : 'details'
          });
          matchedNotifications.push(notification);
        }
      }

      console.log('Search complete:', {
        matches: matchedNotifications.length,
        matchedIds: matchedNotifications.map(n => n.id)
      });

      setFilteredNotifications({
        received: receivedNotifications,
        sent: matchedNotifications
      });
    };

    await filterNotifications();
  };

  // Immediate radial/type filter handler (no button needed)
  const handleTypeFilter = (type) => {
    const updatedTypes = selectedTypes.includes(type)
      ? selectedTypes.filter(t => t !== type)
      : [...selectedTypes, type];
    
    setSelectedTypes(updatedTypes);

    const typeFilteredResults = {
      received: receivedNotifications.filter(notification => {
        const matchesType = updatedTypes.length === 0 || 
                           updatedTypes.includes(notification.type);
        
        // Include search term in type filter
        const matchesSearch = !searchTerm || (
          notification.details?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.senderName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.type?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return matchesType && matchesSearch;
      }),

      sent: sentNotifications.filter(notification => {
        const matchesType = updatedTypes.length === 0 || 
                           updatedTypes.includes(notification.type);
        
        // Include search term in type filter
        const matchesSearch = !searchTerm || (
          notification.details?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.recipientName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          notification.type?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        return matchesType && matchesSearch;
      })
    };

    setFilteredNotifications(typeFilteredResults);
    setShowAllNotifications(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersQuery = query(collection(db, 'users'));
        const usersSnapshot = await getDocs(usersQuery);
        const usersData = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [user]);

  useEffect(() => {
    console.log('Current notifications:', {
      received: receivedNotifications,
      sent: sentNotifications
    });
  }, [receivedNotifications, sentNotifications]);

  // Handle date input changes
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setDateRange(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Add error handling for connection issues
  useEffect(() => {
    const notificationsQuery = query(
      collection(db, 'notifications'),
      where('userId', '==', user?.uid),
      orderBy('timestamp', 'desc'),
      limit(20)
    );

    const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
      // ... handle snapshot updates ...
    }, (error) => {
      console.error('Firestore connection error:', error);
      setToast({
        message: 'Connection issue detected. Trying to reconnect...',
        type: 'warning'
      });
      
      setTimeout(() => {
        fetchNotifications();
      }, 5000);
    });

    return () => unsubscribe();
  }, [user]);

  if (loading) {
    return <div>Loading notifications...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="notifications-container">
      <Sidebar />
      <div className="notifications-content">
        <header className="page-header">
          <h1>Notifications</h1>
          <div className="filter-controls">
            <div className="notification-type-filter">
              <label className="radio-label">
                <input
                  type="radio"
                  name="notificationType"
                  value="all"
                  checked={notificationType === 'all'}
                  onChange={(e) => handleNotificationTypeChange(e.target.value)}
                />
                All
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="notificationType"
                  value="times"
                  checked={notificationType === 'times'}
                  onChange={(e) => handleNotificationTypeChange(e.target.value)}
                />
                Times
              </label>
              <label className="radio-label">
                <input
                  type="radio"
                  name="notificationType"
                  value="runs"
                  checked={notificationType === 'runs'}
                  onChange={(e) => handleNotificationTypeChange(e.target.value)}
                />
                Runs
              </label>
            </div>
            <div className="date-filter">
              <input
                type="date"
                name="startDate"
                value={dateRange.startDate}
                onChange={handleDateChange}
                className="date-input"
              />
              <input
                type="date"
                name="endDate"
                value={dateRange.endDate}
                onChange={handleDateChange}
                className="date-input"
              />
              <button 
                onClick={handleDateFilter}
                className="filter-button"
                disabled={!dateRange.startDate || !dateRange.endDate}
              >
                Apply Filter
              </button>
            </div>
            <div className="action-buttons">
              <button 
                onClick={handleShowAll} 
                className="show-all-button"
              >
                Show All
              </button>
              <button 
                onClick={fetchNotifications} 
                className="refresh-button"
              >
                Refresh Notifications
              </button>
            </div>
            <div className="search-container">
              <input
                type="text"
                placeholder="Search notifications by details or name..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
              />
            </div>
          </div>
        </header>
        <main>
          <section className="received-notifications">
            <h2>Received Notifications</h2>
            {receivedNotifications.length === 0 ? (
              <p>No received notifications to display.</p>
            ) : (
              <div className="table-container">
                <table className="notifications-table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Details</th>
                      <th>From</th>
                      <th>Date</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!showAllNotifications 
                      ? filteredNotifications.received 
                      : receivedNotifications
                    ).map(notification => (
                      <NotificationItem 
                        key={notification.id} 
                        notification={notification}
                        onAcknowledge={handleAcknowledge}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {hasMore && !loading && (
              <button onClick={loadMore} className="load-more-button">
                Load More
              </button>
            )}
          </section>

          <section className="sent-notifications">
            <h2>Sent Notifications</h2>
            {sentNotifications.length === 0 ? (
              <p>No sent notifications to display.</p>
            ) : (
              <div className="table-container">
                <table className="notifications-table">
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Details</th>
                      <th>To</th>
                      <th>Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!showAllNotifications 
                      ? filteredNotifications.sent 
                      : sentNotifications
                    ).map(notification => (
                      <NotificationItem 
                        key={notification.id} 
                        notification={notification}
                        onAcknowledge={handleAcknowledge}
                        isSentNotification={true}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </section>
        </main>
      </div>
      {toast && (
        <Toast 
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
          duration={3000}
        />
      )}
    </div>
  );
}

export default Notifications;
