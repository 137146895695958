import React, { useState, useEffect, useContext } from 'react';
import Sidebar from '../components/Sidebar';
import './Departments.css';
import '../styles/SharedStyles.css';
import { db, storage } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, query, where, writeBatch } from 'firebase/firestore';
import { AuthContext } from '../contexts/AuthContext';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function Departments() {
  const [departments, setDepartments] = useState([]);
  const [newDepartment, setNewDepartment] = useState({
    name: '',
    headOfDepartment: '',
    description: '',
    leaders: []
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [editingDepartment, setEditingDepartment] = useState(null);
  const { user } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedDepartmentId, setExpandedDepartmentId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    if (user) {
      fetchDepartments();
    }
  }, [user]);

  const fetchDepartments = async () => {
    setIsLoading(true);
    try {
      const querySnapshot = await getDocs(collection(db, 'departments'));
      const departmentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDepartments(departmentList);
    } catch (error) {
      console.error('Error fetching departments:', error);
      setError(`Error fetching departments: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddDepartment = async (e) => {
    e.preventDefault();
    if (newDepartment.name.trim()) {
      try {
        await addDoc(collection(db, 'departments'), newDepartment);
        setNewDepartment({ name: '', headOfDepartment: '', description: '', leaders: [] });
        fetchDepartments();
      } catch (error) {
        console.error('Error adding department:', error);
      }
    }
  };

  const handleUpdateDepartment = async (updatedDepartment) => {
    try {
      await updateDoc(doc(db, 'departments', updatedDepartment.id), updatedDepartment);
      setEditingDepartment(null);
      fetchDepartments();
    } catch (error) {
      console.error('Error updating department:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDepartment({ ...newDepartment, [name]: value });
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditingDepartment({ ...editingDepartment, [name]: value });
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredDepartments = departments.filter(dept =>
    dept.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const canEditDepartment = (departmentId) => {
    if (!user) return false;
    
    // Allow all admins to edit
    if (user.role === 'AppAdmin' || 
        user.role === 'SuperAdmin' || 
        user.role === 'ProductionAdmin') {
      return true;
    }
    
    // Allow supervisors of the specific department
    return user.role === 'Supervisor' && user.department === departmentId;
  };

  const EditDepartmentForm = ({ department, onSubmit, onCancel }) => {
    const [editForm, setEditForm] = useState({
      ...department,
      leaders: department.leaders || []
    });
    const [newLeader, setNewLeader] = useState({
      name: '',
      position: '',
      phone: '',
      email: ''
    });

    const handleSubmit = (e) => {
      e.preventDefault();
      onSubmit(editForm);
    };

    const handleLeaderAdd = (e) => {
      e.preventDefault();
      if (newLeader.name && newLeader.position) {
        setEditForm(prev => ({
          ...prev,
          leaders: [...prev.leaders, newLeader]
        }));
        setNewLeader({ name: '', position: '', phone: '', email: '' });
      }
    };

    const removeLeader = (index) => {
      setEditForm(prev => ({
        ...prev,
        leaders: prev.leaders.filter((_, i) => i !== index)
      }));
    };

    return (
      <div className="edit-department-popup" onClick={e => e.stopPropagation()}>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            value={editForm.name}
            onChange={(e) => setEditForm(prev => ({ ...prev, name: e.target.value }))}
            placeholder="Department Name"
            required
          />
          <input
            type="text"
            name="headOfDepartment"
            value={editForm.headOfDepartment}
            onChange={(e) => setEditForm(prev => ({ ...prev, headOfDepartment: e.target.value }))}
            placeholder="Head of Department"
          />
          <textarea
            name="description"
            value={editForm.description}
            onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
            placeholder="Department Description"
          />
          
          <div className="department-leaders-section">
            <h4>Department Leaders</h4>
            <div className="add-leader-form">
              <input
                type="text"
                value={newLeader.name}
                onChange={(e) => setNewLeader(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Leader Name"
              />
              <input
                type="text"
                value={newLeader.position}
                onChange={(e) => setNewLeader(prev => ({ ...prev, position: e.target.value }))}
                placeholder="Position"
              />
              <input
                type="tel"
                value={newLeader.phone}
                onChange={(e) => setNewLeader(prev => ({ ...prev, phone: e.target.value }))}
                placeholder="Phone Number"
              />
              <input
                type="email"
                value={newLeader.email}
                onChange={(e) => setNewLeader(prev => ({ ...prev, email: e.target.value }))}
                placeholder="Email Address"
              />
              <button type="button" onClick={handleLeaderAdd} className="add-leader-btn">
                Add Leader
              </button>
            </div>

            <div className="leaders-list">
              {editForm.leaders.map((leader, index) => (
                <div key={index} className="leader-item">
                  <div className="leader-info">
                    <div className="leader-main">
                      <strong>{leader.name}</strong> - {leader.position}
                    </div>
                    <div className="leader-contact">
                      <span>{leader.phone}</span>
                      <span>{leader.email}</span>
                    </div>
                  </div>
                  <div className="leader-actions">
                    <button 
                      type="button" 
                      onClick={() => {
                        setNewLeader(leader);
                        removeLeader(index);
                      }}
                      className="edit-leader-btn"
                    >
                      Edit
                    </button>
                    <button 
                      type="button" 
                      onClick={() => removeLeader(index)}
                      className="remove-leader-btn"
                    >
                      ×
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="button-group">
            <button type="submit">Update</button>
            <button type="button" onClick={onCancel}>Cancel</button>
          </div>
        </form>
      </div>
    );
  };

  const DepartmentItem = ({ dept }) => {
    const isExpanded = expandedDepartmentId === dept.id;
    const canEdit = canEditDepartment(dept.id);
    const [showRunRequest, setShowRunRequest] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [runRequest, setRunRequest] = useState({
      name: '',
      department: '',
      requestType: 'pickup',
      address: {
        streetNumber: '',
        streetName: '',
        suite: '',
        city: '',
        state: '',
        zip: ''
      },
      description: '',
      date: '',
      time: '',
      photos: []
    });

    const handleFileSelect = (e) => {
      const files = Array.from(e.target.files);
      setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    };

    const removeFile = (indexToRemove) => {
      setSelectedFiles(prevFiles => 
        prevFiles.filter((_, index) => index !== indexToRemove)
      );
    };

    const handleRunRequestSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setSubmitError(null);

      try {
        // Upload photos first if any exist
        const photoUrls = [];
        if (selectedFiles.length > 0) {
          for (const file of selectedFiles) {
            const storageRef = ref(storage, `runRequests/${Date.now()}_${file.name}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            photoUrls.push(url);
          }
        }

        // Create the run request document
        const runRequestData = {
          ...runRequest,
          photos: photoUrls,
          status: 'Pending',
          createdAt: new Date(),
          createdBy: user.uid,
          createdByName: user.displayName || user.email,
          updatedAt: new Date(),
          completedAt: null,
          assignedTo: null,
          acknowledgement: {
            received: false,
            receivedAt: null,
            started: false,
            startedAt: null,
            completed: false,
            completedAt: null
          }
        };

        // Add run request to Firestore
        const runRequestRef = await addDoc(collection(db, 'runRequests'), runRequestData);

        // Create notifications
        const notificationsData = [
          // Notification for supervisors
          {
            type: 'runRequest',
            status: 'pending',
            title: 'New Run Request',
            message: `New run request from ${runRequestData.createdByName}`,
            runRequestId: runRequestRef.id,
            createdAt: new Date(),
            forRole: 'Supervisor',
            forDepartment: 'Transportation',
            read: false,
            action: 'assign',
            metadata: {
              requestType: runRequest.requestType,
              date: runRequest.date,
              time: runRequest.time
            }
          },
          // Confirmation notification for requester
          {
            type: 'runRequest',
            status: 'sent',
            title: 'Run Request Submitted',
            message: 'Your run request has been submitted and is pending assignment',
            runRequestId: runRequestRef.id,
            createdAt: new Date(),
            forUserId: user.uid,
            read: false,
            action: 'view',
            metadata: {
              requestType: runRequest.requestType,
              date: runRequest.date,
              time: runRequest.time
            }
          }
        ];

        // Add notifications to Firestore
        const batch = writeBatch(db);
        notificationsData.forEach((notification) => {
          const notificationRef = doc(collection(db, 'notifications'));
          batch.set(notificationRef, notification);
        });
        await batch.commit();

        setSubmitSuccess(true);
        
        // Reset form after 2 seconds
        setTimeout(() => {
          setShowRunRequest(false);
          setRunRequest({
            name: '',
            department: '',
            requestType: 'pickup',
            address: {
              streetNumber: '',
              streetName: '',
              suite: '',
              city: '',
              state: '',
              zip: ''
            },
            description: '',
            date: '',
            time: '',
            photos: []
          });
          setSelectedFiles([]);
          setSubmitSuccess(false);
        }, 2000);

      } catch (error) {
        console.error('Error submitting run request:', error);
        setSubmitError('Failed to submit run request. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    };

    const handleRunRequestChange = (e) => {
      const { name, value } = e.target;
      if (name.startsWith('address.')) {
        const addressField = name.split('.')[1];
        setRunRequest(prev => ({
          ...prev,
          address: {
            ...prev.address,
            [addressField]: value
          }
        }));
      } else {
        setRunRequest(prev => ({
          ...prev,
          [name]: value
        }));
      }
    };

    return (
      <div 
        className={`department-item ${isExpanded ? 'expanded' : ''}`}
        onClick={() => setExpandedDepartmentId(isExpanded ? null : dept.id)}
      >
        <div className="department-header">
          <h3>{dept.name}</h3>
          {canEdit && (
            <button 
              onClick={(e) => {
                e.stopPropagation();
                setEditingDepartment(dept);
              }}
              className="edit-button"
            >
              Edit
            </button>
          )}
        </div>

        {!isExpanded ? (
          <p className="department-preview">
            <strong>Head of Department:</strong> {dept.headOfDepartment || 'Not assigned'}
          </p>
        ) : dept.name === 'Transportation' ? (
          <div className="department-expanded-content" onClick={e => e.stopPropagation()}>
            {!showRunRequest ? (
              <>
                {/* Department Leaders Section First */}
                {dept.leaders && dept.leaders.length > 0 && (
                  <div className="department-leaders">
                    <h4>Department Leaders</h4>
                    <div className="leaders-grid">
                      {dept.leaders.map((leader, index) => (
                        <div key={index} className="leader-card">
                          <h5>{leader.name}</h5>
                          <p className="leader-position">{leader.position}</p>
                          <div className="leader-contact-info">
                            {leader.phone && (
                              <p className="leader-phone">
                                <i className="fas fa-phone"></i> {leader.phone}
                              </p>
                            )}
                            {leader.email && (
                              <p className="leader-email">
                                <i className="fas fa-envelope"></i> {leader.email}
                              </p>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Then About and Services */}
                <div className="department-info">
                  <h4>About</h4>
                  <p>The Transportation Department handles all vehicle logistics, crew transportation, and equipment movement for productions. We manage a fleet of vehicles including passenger vans, trucks, and specialty vehicles.</p>
                  
                  <h4>Services</h4>
                  <ul>
                    <li>Equipment pickup and delivery</li>
                    <li>Crew transportation</li>
                    <li>Location scouting support</li>
                    <li>Emergency transportation</li>
                    <li>Vehicle maintenance and management</li>
                  </ul>
                  
                  <button 
                    className="run-request-button"
                    onClick={() => setShowRunRequest(true)}
                  >
                    Submit Run Request
                  </button>
                </div>
              </>
            ) : (
              <div className="run-request-form">
                <h3>Submit Run Request</h3>
                
                {submitSuccess ? (
                  <div className="success-message">
                    <div className="success-content">
                      <span className="success-icon">✓</span>
                      <p>Run request submitted successfully!</p>
                    </div>
                  </div>
                ) : (
                  <form onSubmit={handleRunRequestSubmit}>
                    <div className="form-grid">
                      <div className="form-column">
                        <input
                          type="text"
                          name="name"
                          value={runRequest.name}
                          onChange={handleRunRequestChange}
                          placeholder="Name"
                          required
                        />
                        <select
                          name="department"
                          value={runRequest.department}
                          onChange={handleRunRequestChange}
                          required
                        >
                          <option value="">Select Department</option>
                          {departments.map((department) => (
                            <option key={department.id} value={department.name}>
                              {department.name}
                            </option>
                          ))}
                        </select>
                        <select
                          name="requestType"
                          value={runRequest.requestType}
                          onChange={handleRunRequestChange}
                          required
                        >
                          <option value="pickup">Pickup</option>
                          <option value="dropoff">Drop-off</option>
                        </select>
                      </div>
                      <div className="form-column">
                        <input
                          type="text"
                          name="date"
                          value={runRequest.date}
                          onChange={handleRunRequestChange}
                          placeholder="Date (MM/DD/YYYY)"
                          required
                        />
                        <input
                          type="text"
                          name="time"
                          value={runRequest.time}
                          onChange={handleRunRequestChange}
                          placeholder="Time (HH:MM AM/PM)"
                          required
                        />
                      </div>
                    </div>

                    <div className="address-section">
                      <h4>Address</h4>
                      <div className="address-grid">
                        <input
                          type="text"
                          name="address.streetNumber"
                          value={runRequest.address.streetNumber}
                          onChange={handleRunRequestChange}
                          placeholder="Street Number"
                          required
                        />
                        <input
                          type="text"
                          name="address.streetName"
                          value={runRequest.address.streetName}
                          onChange={handleRunRequestChange}
                          placeholder="Street Name"
                          required
                        />
                        <input
                          type="text"
                          name="address.suite"
                          value={runRequest.address.suite}
                          onChange={handleRunRequestChange}
                          placeholder="Suite (optional)"
                        />
                      </div>
                      <div className="address-grid">
                        <input
                          type="text"
                          name="address.city"
                          value={runRequest.address.city}
                          onChange={handleRunRequestChange}
                          placeholder="City"
                          required
                        />
                        <input
                          type="text"
                          name="address.state"
                          value={runRequest.address.state}
                          onChange={handleRunRequestChange}
                          placeholder="State"
                          required
                        />
                        <input
                          type="text"
                          name="address.zip"
                          value={runRequest.address.zip}
                          onChange={handleRunRequestChange}
                          placeholder="ZIP Code"
                          required
                        />
                      </div>
                    </div>

                    <textarea
                      name="description"
                      value={runRequest.description}
                      onChange={handleRunRequestChange}
                      placeholder="Description of Items"
                      required
                    />

                    <div className="photo-upload-section">
                      <label className="file-upload-label">
                        <input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={handleFileSelect}
                          className="file-input"
                        />
                        Add Photos
                      </label>
                      
                      {selectedFiles.length > 0 && (
                        <div className="selected-files">
                          {selectedFiles.map((file, index) => (
                            <div key={index} className="file-preview">
                              <img
                                src={URL.createObjectURL(file)}
                                alt={`Preview ${index + 1}`}
                              />
                              <button
                                type="button"
                                onClick={() => removeFile(index)}
                                className="remove-file"
                              >
                                ×
                              </button>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    {submitError && (
                      <div className="error-message">
                        {submitError}
                      </div>
                    )}

                    <div className="button-group">
                      <button 
                        type="button" 
                        onClick={() => {
                          setShowRunRequest(false);
                          setSelectedFiles([]);
                          setSubmitError(null);
                        }}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </button>
                      <button 
                        type="submit" 
                        disabled={isSubmitting}
                        className={isSubmitting ? 'submitting' : ''}
                      >
                        {isSubmitting ? 'Submitting...' : 'Submit Request'}
                      </button>
                    </div>
                  </form>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="department-expanded-content" onClick={e => e.stopPropagation()}>
            <p><strong>Head of Department:</strong> {dept.headOfDepartment || 'Not assigned'}</p>
            <p><strong>Description:</strong> {dept.description || 'No description available'}</p>
            
            {dept.leaders && dept.leaders.length > 0 && (
              <div className="department-leaders">
                <h4>Department Leaders</h4>
                <div className="leaders-grid">
                  {dept.leaders.map((leader, index) => (
                    <div key={index} className="leader-card">
                      <h5>{leader.name}</h5>
                      <p className="leader-position">{leader.position}</p>
                      <div className="leader-contact-info">
                        {leader.phone && (
                          <p className="leader-phone">
                            <i className="fas fa-phone"></i> {leader.phone}
                          </p>
                        )}
                        {leader.email && (
                          <p className="leader-email">
                            <i className="fas fa-envelope"></i> {leader.email}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {editingDepartment && editingDepartment.id === dept.id && (
          <EditDepartmentForm 
            department={editingDepartment}
            onSubmit={handleUpdateDepartment}
            onCancel={() => setEditingDepartment(null)}
          />
        )}
      </div>
    );
  };

  if (isLoading) {
    return <div>Loading departments...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="departments-container">
      <Sidebar />
      <div className="departments-content">
        <header className="page-header">
          <h1>Departments</h1>
          <p>Organize and manage production departments</p>
        </header>
        <main>
          <section className="add-department">
            <h2>Add New Department</h2>
            <form onSubmit={handleAddDepartment}>
              <input
                type="text"
                name="name"
                value={newDepartment.name}
                onChange={handleInputChange}
                placeholder="Department Name"
                required
              />
              <input
                type="text"
                name="headOfDepartment"
                value={newDepartment.headOfDepartment}
                onChange={handleInputChange}
                placeholder="Head of Department"
              />
              <textarea
                name="description"
                value={newDepartment.description}
                onChange={handleInputChange}
                placeholder="Department Description"
              />
              <button type="submit">Add Department</button>
            </form>
          </section>
          <section className="search-departments">
            <h2>Search Departments</h2>
            <input
              type="text"
              placeholder="Search departments..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </section>
          <section className="department-list">
            <h2>Existing Departments</h2>
            <div className="department-grid">
              {filteredDepartments.map((dept) => (
                <DepartmentItem key={dept.id} dept={dept} />
              ))}
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default Departments;