import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';
import { auth } from './firebase'; // Import auth from firebase
import { signOut } from 'firebase/auth'; // Import signOut function
import WelcomePage from './pages/WelcomePage';
import LandingPage from './pages/LandingPage';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import Productions from './pages/Productions';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Reports from './pages/Reports';
import CrewMembers from './pages/CrewMembers';
import Departments from './pages/Departments';
import Times from './pages/Times';
import Dispatch from './pages/Dispatch';
import Notifications from './pages/Notifications';
import Messages from './pages/Messages';
import Profile from './pages/Profile';
import Vehicles from './pages/crewVehicles';
import ProtectedRoute from './components/ProtectedRoute';
import UserRoleManager from './components/UserRoleManager';
import TimeSheet from './components/TimeSheet';
import AddNotification from './components/AddNotification';
import AddVehicle from './components/AddVehicle';
import SuperAdminRoute from './components/SuperAdminRoute';
import VehiclesPage from './pages/VehiclesPage';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import './App.css';
import HamburgerMenu from './components/HamburgerMenu';
import { useAuth } from './hooks/useAuth';
import ProfileCompletion from './pages/ProfileCompletion';
import MealReceipts from './pages/MealReceipts';
import ReceiptViewer from './components/ReceiptViewer';
import PCardReceipts from './pages/PCardReceipts';
import UnderConstruction from './pages/UnderConstruction';
import Budget from './pages/Budget';
import OfflineStatus from './components/OfflineStatus';

function AppContent() {
  const { user, setUser } = useAuth(); // Get setUser from useAuth
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 768);
  const location = useLocation();
  const navigate = useNavigate(); // Add this line
  const visibleLinks = ['productions', 'crew-members', 'vehicles', 'reports', 'messages'];
  const newNotificationsCount = 0; // Replace with actual notification count logic

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null); // Clear the user from context
      navigate('/'); // Redirect to home page after logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  const publicRoutes = ['/', '/login', '/signup'];
  const shouldShowSidebar = user && !publicRoutes.includes(location.pathname);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="app-container">
      <OfflineStatus />
      {user && (
        <div className="navbar-container">
          <Navbar toggleSidebar={toggleSidebar} />
        </div>
      )}
      {user && shouldShowSidebar && window.innerWidth >= 768 && <Sidebar isOpen={isSidebarOpen} />}
      {user && shouldShowSidebar && window.innerWidth < 768 && (
        <HamburgerMenu 
          isOpen={isSidebarOpen} 
          visibleLinks={visibleLinks} 
          newNotificationsCount={newNotificationsCount}
          handleLogout={handleLogout}
          toggleSidebar={toggleSidebar}
        />
      )}
      <div className={`main-content ${shouldShowSidebar ? 'sidebar-open' : ''}`}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          
          {/* Protected Routes */}
          <Route path="/welcome" element={<ProtectedRoute><WelcomePage /></ProtectedRoute>} />
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path="/productions" element={<ProtectedRoute><Productions /></ProtectedRoute>} />
          <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
          <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
          <Route path="/crew-members" element={<ProtectedRoute><CrewMembers /></ProtectedRoute>} />
          <Route path="/departments" element={<ProtectedRoute><Departments /></ProtectedRoute>} />
          <Route path="/times" element={<ProtectedRoute><Times /></ProtectedRoute>} />
          <Route path="/dispatch" element={<ProtectedRoute><Dispatch /></ProtectedRoute>} />
          <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
          <Route path="/messages" element={<ProtectedRoute><Messages /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route path="/vehicles" element={<ProtectedRoute><Vehicles /></ProtectedRoute>} />
          <Route path="/vehicles-page" element={<VehiclesPage />} />
          
          {user && user.role === 'superAdmin' && (
            <Route path="/manage-roles" element={<SuperAdminRoute><UserRoleManager /></SuperAdminRoute>} />
          )}
          
          <Route path="/timesheet" element={<ProtectedRoute><TimeSheet /></ProtectedRoute>} />
          <Route path="/add-notification" element={<ProtectedRoute><AddNotification /></ProtectedRoute>} />
          <Route path="/add-vehicle" element={<ProtectedRoute><AddVehicle /></ProtectedRoute>} />
          <Route path="/complete-profile" element={<ProfileCompletion />} />
          <Route path="/meal-receipts" element={<ProtectedRoute><MealReceipts /></ProtectedRoute>} />
          <Route path="/view-receipts" element={<ProtectedRoute><ReceiptViewer /></ProtectedRoute>} />
          <Route path="/pcard-receipts" element={<ProtectedRoute><PCardReceipts /></ProtectedRoute>} />
          <Route path="/under-construction" element={<UnderConstruction />} />
          <Route path="/budget" element={<ProtectedRoute><Budget /></ProtectedRoute>} />
        </Routes>
      </div>
      {(!user || (user && !user.profileCompleted && user.loginCount === 0)) && <ProfileCompletion />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
