import React, { useState, useEffect, useContext } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../firebase';
import { AuthContext } from '../contexts/AuthContext';
import Sidebar from '../components/Sidebar';
import EditProfilePopup from '../components/EditProfilePopup';
import './Profile.css';
import '../styles/SharedStyles.css';

function Profile() {
  const { user } = useContext(AuthContext);
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [productionHistory, setProductionHistory] = useState([]);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [currentProduction, setCurrentProduction] = useState(null);
  const [error, setError] = useState(null);
  const [departmentName, setDepartmentName] = useState('');

  const fetchDepartmentName = async (departmentId) => {
    if (!departmentId) return;
    try {
      const departmentDoc = await getDoc(doc(db, 'departments', departmentId));
      if (departmentDoc.exists()) {
        setDepartmentName(departmentDoc.data().name);
      }
    } catch (error) {
      console.error("Error fetching department name:", error);
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      if (user) {
        try {
          const docRef = doc(db, 'users', user.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const profileData = docSnap.data();
            console.log("Fetched profile data:", profileData); // Log the entire profile data
            setProfile(profileData);
            setIsSupervisor(profileData.role === 'supervisor');
            
            // Set current production directly from the profile data
            if (profileData.production) {
              setCurrentProduction(profileData.production);
            }

            // Fetch department name
            if (profileData.department) {
              fetchDepartmentName(profileData.department);
            }

            // Fetch production history
            const productionHistoryRef = doc(db, 'productionHistory', user.uid);
            const productionHistorySnap = await getDoc(productionHistoryRef);
            if (productionHistorySnap.exists()) {
              setProductionHistory(productionHistorySnap.data().history || []);
            }
          } else {
            console.log("No such document!");
            setError("Profile not found");
          }
        } catch (error) {
          console.error("Error fetching profile:", error);
          setError(`Failed to fetch profile: ${error.message}`);
        }
      }
    };
    fetchProfile();
  }, [user]);

  const getUserRole = async () => {
    if (user) {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      return userDoc.data().role;
    }
    return null;
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async (editedProfile) => {
    try {
      console.log("Starting profile update...");
      console.log("Edited profile data:", editedProfile);
      const userRef = doc(db, 'users', user.uid);
      
      // Check if there's a new photo file to upload
      if (editedProfile.newPhotoFile) {
        try {
          console.log("New photo file detected, starting upload...");
          const storage = getStorage();
          const storageRef = ref(storage, `profile_photos/${user.uid}`);
          await uploadBytes(storageRef, editedProfile.newPhotoFile);
          console.log("Photo uploaded successfully");
          const photoURL = await getDownloadURL(storageRef);
          console.log("Photo URL retrieved:", photoURL);
          editedProfile.photoURL = photoURL;
        } catch (storageError) {
          console.error("Error uploading profile photo:", storageError);
          // If photo upload fails, continue with profile update without changing the photo
        }
      }
      
      // Remove the newPhotoFile from the profile data before saving to Firestore
      const { newPhotoFile, ...profileToSave } = editedProfile;
      
      console.log("Updating Firestore document with:", profileToSave);
      await updateDoc(userRef, profileToSave);
      console.log("Firestore document updated successfully");
      
      setProfile(profileToSave);
      console.log("Updated profile state:", profileToSave);
      setIsEditing(false);
      console.log("Profile update completed");
    } catch (error) {
      console.error("Detailed error updating profile: ", error);
      console.error("Error name:", error.name);
      console.error("Error message:", error.message);
      console.error("Error stack:", error.stack);
      throw new Error(`Failed to update profile: ${error.message}`);
    }
  };

  const handlePayrateChange = async (e) => {
    const newPayrate = parseFloat(e.target.value).toFixed(2);
    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { payrate: newPayrate });
      setProfile({ ...profile, payrate: newPayrate });
    } catch (error) {
      console.error("Error updating payrate: ", error);
    }
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!profile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-container">
      <Sidebar />
      <div className="profile-content">
        <header className="page-header">
          <h1>Profile</h1>
          <p>Manage your personal information and settings</p>
        </header>
        <main>
          <div className="profile-card">
            <div className="profile-header">
              <h2>Your Profile</h2>
              <button onClick={handleEdit} className="edit-button">Edit Profile</button>
            </div>
            <img src={profile.photoURL || 'default-avatar.png'} alt="Profile" className="profile-picture" />
            <h2>{profile.firstName} {profile.lastName}</h2>
            <p><strong>Email:</strong> {profile.email}</p>
            <p><strong>Phone:</strong> {profile.phone || 'Not provided'}</p>
            <p><strong>Role:</strong> {profile.role || 'Not specified'}</p>
            
            <h3>Current Production</h3>
            {currentProduction ? (
              <div className="current-production-info">
                <p>{currentProduction}</p>
              </div>
            ) : (
              <p>Not currently assigned to a production</p>
            )}
            
            <p><strong>Department:</strong> {departmentName || 'Not specified'}</p>
            <p><strong>Address:</strong> {profile.address || 'Not provided'}</p>
            <p><strong>State:</strong> {profile.state || 'Not provided'}</p>
            <p><strong>Zipcode:</strong> {profile.zipcode || 'Not provided'}</p>
            <p><strong>Birth Date:</strong> {profile.birthDate || 'Not provided'}</p>
            <p><strong>Last 5 of SSN:</strong> {profile.lastFiveSSN || 'Not provided'}</p>
            
            <p>
              <strong>Pay Rate:</strong>{' '}
              {profile.rate
                ? `$${parseFloat(profile.rate).toFixed(2)}/hr`
                : 'Not set'}
            </p>

            <h3>Emergency Contacts</h3>
            {profile.emergencyContacts && profile.emergencyContacts.map((contact, index) => (
              <div key={index} className="emergency-contact-info">
                <p><strong>Contact {index + 1}:</strong></p>
                <p>Name: {contact.name}</p>
                <p>Phone: {contact.phone}</p>
                <p>Email: {contact.email}</p>
                <p>Address: {contact.address}</p>
              </div>
            ))}

            <h3>Vehicles</h3>
            {profile.vehicles && profile.vehicles.map((vehicle, index) => (
              <div key={index} className="vehicle-info">
                <p><strong>Vehicle {index + 1}:</strong></p>
                <p>Make: {vehicle.make}</p>
                <p>Color: {vehicle.color}</p>
                <p>Plate Number: {vehicle.plateNumber}</p>
                <p>Plate State: {vehicle.plateState}</p>
              </div>
            ))}
          </div>
          
          <div className="production-history-card">
            <h2>Production History</h2>
            <table className="production-history-table">
              <thead>
                <tr>
                  <th>Show Name</th>
                  <th>Studio</th>
                  <th>Department</th>
                  <th>Role</th>
                  <th>Position</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {productionHistory.map((production, index) => (
                  <tr key={index}>
                    <td>{production.showName}</td>
                    <td>{production.studio}</td>
                    <td>{production.department}</td>
                    <td>{production.role}</td>
                    <td>{production.position}</td>
                    <td>{production.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
      {isEditing && (
        <EditProfilePopup
          profile={profile}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
}

export default Profile;