import React, { useState, useEffect, useContext } from 'react';
import { collection, addDoc, getDocs, query, where, Timestamp, serverTimestamp, updateDoc, doc, orderBy } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import Sidebar from '../components/Sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Dispatch.css';
import '../styles/SharedStyles.css';
import { AuthContext } from '../contexts/AuthContext';
import Toast from '../components/Toast';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function Dispatch() {
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [newEntry, setNewEntry] = useState({
    crewMember: '',
    callTime: '',
    wrapTime: '',
    date: ''
  });
  const [crewMembers, setCrewMembers] = useState([]);
  const [filteredCrewMembers, setFilteredCrewMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCrewMembers, setSelectedCrewMembers] = useState([]);
  const [assignedTimes, setAssignedTimes] = useState([]);
  const [activeView, setActiveView] = useState('dispatchTime');
  const [dispatchedRuns, setDispatchedRuns] = useState([]);
  const [newRun, setNewRun] = useState({
    title: '',
    date: '',
    assignedTo: '',
    status: 'Pending',
    runType: 'pickup',
    address: '',
    itemDescription: '',
    picture: null
  });
  const [error, setError] = useState(null);
  const [unassignedCrewMembers, setUnassignedCrewMembers] = useState([]);
  const [assignmentConfirmation, setAssignmentConfirmation] = useState(null);
  const [selectedCrewMembersForRun, setSelectedCrewMembersForRun] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [futureRuns, setFutureRuns] = useState([]);
  const [toast, setToast] = useState(null);
  const [expandedRun, setExpandedRun] = useState(null);

  useEffect(() => {
    if (user) {
      fetchData();
    } else {
      setIsLoading(false);
    }
  }, [user]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchCrewMembers(),
        fetchAssignedTimes(),
        fetchDispatchedRuns(),
        checkUnassignedCrewMembers()
      ]);
    } catch (error) {
      handleFirestoreError(error, 'fetching data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFirestoreError = (error, operation) => {
    console.error(`Error during ${operation}:`, error);
    if (error.code === 'permission-denied') {
      setError(`You don't have permission to ${operation}. Please contact an administrator.`);
    } else {
      setError(`An error occurred while ${operation}. Please try again.`);
    }
  };

  useEffect(() => {
    filterCrewMembers();
  }, [searchTerm, crewMembers]);

  const getWeekDates = (date) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(date);
      day.setDate(date.getDate() - date.getDay() + i);
      week.push(day);
    }
    return week;
  };

  const fetchCrewMembers = async () => {
    try {
      console.log('Fetching crew members...'); // Debug log
      console.log('Current user role:', user.role); // Debug log
      console.log('Current user production:', user.production); // Debug log

      const usersCollection = collection(db, 'users');
      let usersQuery;

      if (user.role === 'superAdmin' || user.role === 'admin') {
        usersQuery = usersCollection;
      } else if (user.role === 'supervisor' || user.role === 'Supervisor') {
        usersQuery = query(usersCollection, 
          where('production', '==', user.production),
          where('role', 'in', ['crewMember', 'Crew Member'])
        );
      } else {
        // For other roles, don't fetch any crew members
        setCrewMembers([]);
        setFilteredCrewMembers([]);
        return;
      }

      const querySnapshot = await getDocs(usersQuery);
      const crewMemberList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      console.log('Fetched crew members:', crewMemberList); // Debug log
      setCrewMembers(crewMemberList);
      setFilteredCrewMembers(crewMemberList);
    } catch (error) {
      console.error('Error fetching crew members:', error); // Detailed error log
      handleFirestoreError(error, 'fetching crew members');
    }
  };

  const fetchAssignedTimes = async () => {
    const startOfDay = new Date(selectedDate);
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date(selectedDate);
    endOfDay.setHours(23, 59, 59, 999);

    try {
      console.log('Fetching time entries for:', startOfDay, 'to', endOfDay);
      const querySnapshot = await getDocs(
        query(collection(db, 'timeEntries'),
          where('date', '>=', Timestamp.fromDate(startOfDay)),
          where('date', '<=', Timestamp.fromDate(endOfDay))
        )
      );
      const times = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          date: data.date.toDate(),
          startTime: data.startTime || null,
          endTime: data.endTime || null
        };
      });
      console.log('Fetched time entries:', times);
      setAssignedTimes(times);
    } catch (error) {
      console.error('Error fetching time entries:', error);
      setError('An error occurred while fetching time entries. Please try again.');
    }
  };

  const groupAssignedTimes = () => {
    const grouped = {};
    assignedTimes.forEach(time => {
      if (!grouped[time.crewMember]) {
        grouped[time.crewMember] = time;
      }
    });
    return grouped;
  };

  const fetchDispatchedRuns = async () => {
    try {
      // Calculate today and yesterday's dates
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      console.log('Date range:', { today, yesterday });

      // First get crew members
      const crewQuery = query(
        collection(db, 'users'),
        where('department', '==', user.department),
        where('production', '==', user.production)
      );
      const crewSnapshot = await getDocs(crewQuery);
      const crewList = crewSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      console.log('Crew list fetched:', crewList.length, 'members');

      // Simplified query for dispatched runs
      const runsQuery = query(
        collection(db, 'dispatchedRuns'), 
        where('createdBy', '==', user.uid)
      );
      
      const querySnapshot = await getDocs(runsQuery);
      console.log('Raw runs fetched:', querySnapshot.size);

      const runs = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dateObj: data.date?.toDate() || new Date() // Safely convert date
        };
      });

      // Filter for recent runs and add crew names
      const recentRuns = runs
        .filter(run => {
          const runDate = run.dateObj;
          const isRecent = runDate >= yesterday && runDate <= today;
          return isRecent;
        })
        .map(run => ({
          ...run,
          assignedToName: crewList.find(cm => cm.id === run.assignedTo)?.name || 'Unknown'
        }))
        .sort((a, b) => b.dateObj - a.dateObj);

      console.log('Filtered recent runs:', recentRuns.length);
      setDispatchedRuns(recentRuns);

    } catch (error) {
      console.error('Detailed fetch error:', {
        error,
        errorMessage: error.message,
        errorCode: error.code,
        errorStack: error.stack
      });
      handleFirestoreError(error, 'fetching dispatched runs');
    }
  };

  const filterCrewMembers = () => {
    let filtered = crewMembers;
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter(member => 
        member.name.toLowerCase().includes(lowerSearchTerm) ||
        (member.position && member.position.toLowerCase().includes(lowerSearchTerm))
      );
    }
    setFilteredCrewMembers(filtered);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleCrewMemberCheckbox = (crewMemberId) => {
    setSelectedCrewMembers(prev => {
      if (prev.includes(crewMemberId)) {
        return prev.filter(id => id !== crewMemberId);
      } else {
        return [...prev, crewMemberId];
      }
    });
  };

  const checkUnassignedCrewMembers = () => {
    // Filter crew members who don't have a time entry for the selected date
    const unassigned = crewMembers.filter(member => {
      const hasTimeEntry = assignedTimes.some(time => {
        // Check if this time entry belongs to this crew member
        // and has either a start time (call time) or end time (wrap time)
        return time.userId === member.id && 
               (time.startTime !== null || time.endTime !== null);
      });
      // Return true for crew members who don't have a time entry
      return !hasTimeEntry;
    });

    console.log('Checking unassigned crew members:', {
      allCrewMembers: crewMembers,
      assignedTimes: assignedTimes,
      unassignedMembers: unassigned
    });

    setUnassignedCrewMembers(unassigned);
  };

  const handleDateChange = (date) => {
    // Ensure the date is set to noon UTC
    const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 12, 0, 0));
    setSelectedDate(utcDate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setAssignmentConfirmation(null);
    
    console.log('Dispatch.js - Submit button clicked');
    console.log('Dispatch.js - Selected Date:', selectedDate);
    console.log('Dispatch.js - New Entry:', newEntry);
    console.log('Dispatch.js - Selected Crew Members:', selectedCrewMembers);

    if (selectedCrewMembers.length === 0 || (!newEntry.callTime && !newEntry.wrapTime)) {
      setError('Please select at least one crew member and provide either a call time or a wrap time');
      return;
    }

    try {
      for (const crewMemberId of selectedCrewMembers) {
        // Create a new Date object set to noon on the selected date in UTC
        const entryDate = new Date(Date.UTC(selectedDate.getUTCFullYear(), selectedDate.getUTCMonth(), selectedDate.getUTCDate(), 12, 0, 0));

        const timeEntryData = {
          userId: crewMemberId,
          date: Timestamp.fromDate(entryDate),
          createdAt: serverTimestamp(),
          createdBy: user.uid,
          log: {
            selectedDate: selectedDate.toISOString().split('T')[0],
            clientTime: new Date().toISOString()
          }
        };

        if (newEntry.callTime !== undefined && newEntry.callTime !== '') {
          timeEntryData.startTime = parseFloat(newEntry.callTime);
        }

        if (newEntry.wrapTime !== undefined && newEntry.wrapTime !== '') {
          timeEntryData.endTime = parseFloat(newEntry.wrapTime);
        }

        console.log('Dispatch.js - Time Entry Data:', timeEntryData);

        // Check if an entry already exists for this user and date
        const existingEntryQuery = query(
          collection(db, 'timeEntries'),
          where('userId', '==', crewMemberId),
          where('date', '==', timeEntryData.date)
        );
        const existingEntrySnapshot = await getDocs(existingEntryQuery);

        if (!existingEntrySnapshot.empty) {
          // Update existing entry
          const existingEntryDoc = existingEntrySnapshot.docs[0];
          await updateDoc(doc(db, 'timeEntries', existingEntryDoc.id), timeEntryData);
        } else {
          // Add new entry
          await addDoc(collection(db, 'timeEntries'), timeEntryData);
        }
      }

      console.log('Dispatch.js - All time entries updated successfully');
      setNewEntry({
        callTime: '',
        wrapTime: '',
        date: ''
      });
      setSelectedCrewMembers([]);
      await fetchAssignedTimes();
      checkUnassignedCrewMembers();
      setAssignmentConfirmation('Times assigned successfully!');
      setTimeout(() => setAssignmentConfirmation(null), 3000);
    } catch (error) {
      console.error('Dispatch.js - Detailed error:', error);
      setError(`An error occurred while assigning times: ${error.message}. Please try again.`);
    }
  };

  const handleNewRunChange = (e) => {
    const { name, value } = e.target;
    setNewRun(prev => ({ ...prev, [name]: value }));
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    setNewRun(prev => ({ ...prev, picture: file }));
  };

  const handleCrewMemberCheckboxForRun = (crewMemberId) => {
    setSelectedCrewMembersForRun(prev => {
      if (prev.includes(crewMemberId)) {
        return prev.filter(id => id !== crewMemberId);
      } else {
        return [...prev, crewMemberId];
      }
    });
  };

  const handleDispatchRun = async (e) => {
    e.preventDefault();
    setError(null);

    // Validate required fields
    if (!newRun.title) {
      setToast({ message: 'Please enter a run title', type: 'error' });
      return;
    }
    if (!newRun.date) {
      setToast({ message: 'Please select a date', type: 'error' });
      return;
    }
    if (selectedCrewMembersForRun.length === 0) {
      setToast({ message: 'Please select at least one crew member', type: 'error' });
      return;
    }
    if (!newRun.address) {
      setToast({ message: 'Please enter an address', type: 'error' });
      return;
    }
    if (!newRun.itemDescription) {
      setToast({ message: 'Please enter an item description', type: 'error' });
      return;
    }

    try {
      console.log('Starting dispatch run process');
      
      // Handle image upload if a picture is selected
      let pictureUrl = null;
      if (newRun.picture) {
        try {
          const storage = getStorage();
          const storageRef = ref(storage, `runs/${Date.now()}_${newRun.picture.name}`);
          console.log('Uploading image:', {
            fileName: newRun.picture.name,
            fileSize: newRun.picture.size,
            fileType: newRun.picture.type,
            path: storageRef.fullPath
          });
          
          // Upload the image
          const uploadResult = await uploadBytes(storageRef, newRun.picture);
          console.log('Upload completed:', uploadResult);
          
          // Get the download URL
          pictureUrl = await getDownloadURL(storageRef);
          console.log('Image URL obtained:', pictureUrl);
        } catch (uploadError) {
          console.error('Detailed upload error:', {
            code: uploadError.code,
            message: uploadError.message,
            stack: uploadError.stack
          });
          setToast({ 
            message: `Image upload failed: ${uploadError.message}`, 
            type: 'error' 
          });
          return; // Stop the process if image upload fails
        }
      }

      // Create the run data
      for (const crewMemberId of selectedCrewMembersForRun) {
        try {
          const runData = {
            ...newRun,
            assignedTo: crewMemberId,
            date: Timestamp.fromDate(new Date(newRun.date)),
            pictureUrl: pictureUrl, // Add the image URL
            createdAt: serverTimestamp(),
            createdBy: user.uid,
          };
          delete runData.picture; // Remove the file object

          console.log('Saving run data:', runData);

          const docRef = await addDoc(collection(db, 'dispatchedRuns'), runData);
          console.log('Run saved with ID:', docRef.id);
        } catch (runError) {
          console.error('Error saving run:', runError);
          setToast({ 
            message: `Failed to save run: ${runError.message}`, 
            type: 'error' 
          });
          return;
        }
      }

      // Reset form and show success message
      setNewRun({
        title: '',
        date: '',
        assignedTo: '',
        status: 'Pending',
        runType: 'pickup',
        address: '',
        itemDescription: '',
        picture: null
      });
      setSelectedCrewMembersForRun([]);
      await fetchDispatchedRuns();
      setToast({ message: 'Run dispatched successfully!', type: 'success' });
    } catch (error) {
      console.error('Main error:', error);
      setToast({ 
        message: `Failed to dispatch run: ${error.message}`, 
        type: 'error' 
      });
    }
  };

  const fetchDispatchData = async () => {
    try {
      const dispatchCollection = collection(db, 'dispatches');
      const snapshot = await getDocs(dispatchCollection);
      const dispatchData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      // Set state with dispatchData
    } catch (error) {
      if (error.code === 'permission-denied') {
        console.error('Permission denied:', error.message);
        // Handle the permission error (e.g., show a message to the user)
        setError('You do not have permission to access dispatch data.');
      } else {
        console.error('Firestore error:', error);
        // Handle other types of errors
        setError('An error occurred while fetching dispatch data. Please try again.');
      }
    }
  };

  const handleAddDispatch = async (dispatchData) => {
    try {
      const dispatchCollection = collection(db, 'dispatches');
      await addDoc(dispatchCollection, dispatchData);
      // Update state or refetch data
    } catch (error) {
      if (error.code === 'permission-denied') {
        console.error('Permission denied:', error.message);
        // Handle the permission error (e.g., show a message to the user)
        setError('You do not have permission to add dispatch data.');
      } else {
        console.error('Firestore error:', error);
        // Handle other types of errors
        setError('An error occurred while adding dispatch data. Please try again.');
      }
    }
  };

  const handleUpdateDispatch = async (dispatchId, updatedData) => {
    try {
      const dispatchDoc = doc(db, 'dispatches', dispatchId);
      await updateDoc(dispatchDoc, updatedData);
      // Update state or refetch data
    } catch (error) {
      if (error.code === 'permission-denied') {
        console.error('Permission denied:', error.message);
        // Handle the permission error (e.g., show a message to the user)
        setError('You do not have permission to update dispatch data.');
      } else {
        console.error('Firestore error:', error);
        // Handle other types of errors
        setError('An error occurred while updating dispatch data. Please try again.');
      }
    }
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };

  const formatTime = (time) => {
    if (time === null || time === undefined) return 'N/A';
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    let numericValue = parseFloat(value);

    if (isNaN(numericValue)) return;

    if (name === 'callTime') {
      if (numericValue === -1) {
        setNewEntry(prev => ({ ...prev, callTime: -1, wrapTime: -2 }));
        return;
      }
      numericValue = Math.max(0, Math.min(23.5, numericValue));
    } else if (name === 'wrapTime') {
      numericValue = Math.max(0, Math.min(48, numericValue));
    }

    // Round to nearest 0.5
    numericValue = Math.round(numericValue * 2) / 2;

    setNewEntry(prev => ({ ...prev, [name]: numericValue }));
  };

  const getDisplayTime = (time, isWrapTime = false) => {
    if (time === null || time === undefined) return '';
    
    if (time === -1) return 'Off';
    if (time === -2) return 'N/A';
    
    const hours = Math.floor(time);
    const minutes = Math.round((time - hours) * 60);
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    
    if (isWrapTime && time >= 24) {
      return `${formattedTime} (Next Day)`;
    }
    return formattedTime;
  };

  useEffect(() => {
    if (user) {
      fetchAssignedTimes();
    }
  }, [user, selectedDate]);

  useEffect(() => {
    console.log('Updated assignedTimes:', assignedTimes);
  }, [assignedTimes]);

  const handleNewRunDateChange = (date) => {
    setNewRun(prev => ({ ...prev, date: date }));
  };

  const handleDeselectAll = () => {
    setSelectedCrewMembersForRun([]);
  };

  // Add this function near your other handlers
  const handleSelectAll = () => {
    const allCrewIds = filteredCrewMembers.map(member => member.id);
    setSelectedCrewMembersForRun(allCrewIds);
  };

  const handleRunClick = (runId) => {
    if (expandedRun === runId) {
      setExpandedRun(null); // Collapse if already expanded
    } else {
      setExpandedRun(runId); // Expand the clicked notification
    }
  };

  const fetchAllRuns = async () => {
    try {
      // Get crew members first
      const crewQuery = query(
        collection(db, 'users'),
        where('department', '==', user.department),
        where('production', '==', user.production)
      );
      const crewSnapshot = await getDocs(crewQuery);
      const crewList = crewSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Get all runs
      const runsQuery = query(
        collection(db, 'dispatchedRuns'), 
        where('createdBy', '==', user.uid)
      );
      
      const querySnapshot = await getDocs(runsQuery);
      const allRuns = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          dateObj: data.date?.toDate() || new Date(),
          assignedToName: crewList.find(cm => cm.id === data.assignedTo)?.name || 'Unknown'
        };
      });

      // Split runs into current day and future
      const today = new Date(selectedDate);
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const currentDayRuns = allRuns
        .filter(run => {
          const runDate = new Date(run.dateObj);
          runDate.setHours(0, 0, 0, 0);
          return runDate.getTime() === today.getTime();
        })
        .sort((a, b) => b.dateObj - a.dateObj);

      const futureRunsList = allRuns
        .filter(run => run.dateObj > tomorrow)
        .sort((a, b) => a.dateObj - b.dateObj);

      setDispatchedRuns(currentDayRuns);
      setFutureRuns(futureRunsList);

    } catch (error) {
      console.error('Fetch error:', error);
      handleFirestoreError(error, 'fetching runs');
    }
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() - 1);
    setSelectedDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate);
  };

  useEffect(() => {
    if (user) {
      fetchAllRuns();
    }
  }, [selectedDate, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please log in to access this page.</div>;
  }

  return (
    <div className="dispatch-container">
      <Sidebar />
      <div className="dispatch-content">
        <header className="page-header">
          <h1>Dispatch</h1>
          <p>Manage crew assignments and dispatched runs</p>
        </header>
        {error && <div className="error-message">{error}</div>}
        <div className="dispatch-tabs">
          <button
            className={activeView === 'dispatchTime' ? 'active' : ''}
            onClick={() => setActiveView('dispatchTime')}
          >
            Dispatch Time
          </button>
          <button
            className={activeView === 'dispatchRun' ? 'active' : ''}
            onClick={() => setActiveView('dispatchRun')}
          >
            Dispatch Run
          </button>
        </div>
        <div className="dispatch-view">
          {activeView === 'dispatchTime' ? (
            <div className="dispatch-time-content">
              <section className="add-time-entry">
                <h2>Dispatch Times</h2>
                {error && <div className="error-message">{error}</div>}
                {assignmentConfirmation && <div className="assignment-confirmation">{assignmentConfirmation}</div>}
                <form onSubmit={handleSubmit}>
                  <div className="time-entry-inputs">
                    <div className="date-time-group">
                      <div className="form-group">
                        <label htmlFor="date">Date:</label>
                        <DatePicker
                          selected={selectedDate}
                          onChange={handleDateChange}
                          dateFormat="yyyy-MM-dd"
                        />
                      </div>
                      <div className="time-input-container">
                        <input
                          type="number"
                          id="callTime"
                          name="callTime"
                          value={newEntry.callTime || ''}
                          onChange={handleTimeChange}
                          min="0"
                          max="23.5"
                          step="0.5"
                          placeholder="Call Time (0-23.5)"
                        />
                        <span>{getDisplayTime(newEntry.callTime)}</span>
                      </div>
                    </div>
                    <div className="time-input-container">
                      <input
                        type="number"
                        id="wrapTime"
                        name="wrapTime"
                        value={newEntry.wrapTime || ''}
                        onChange={handleTimeChange}
                        min="0"
                        max="48"
                        step="0.5"
                        placeholder="Wrap Time (0-48)"
                      />
                      <span>{getDisplayTime(newEntry.wrapTime, true)}</span>
                    </div>
                  </div>
                  <button type="submit">Assign Time to Selected Crew Members</button>
                </form>
              </section>
              <section className="crew-selection">
                <div className="form-group">
                  <label htmlFor="crewMember">Search Crew Member:</label>
                  <input
                    type="text"
                    id="crewMember"
                    name="crewMember"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by name or position"
                  />
                </div>
                <div className="crew-members-list">
                  <h3>Crew Members</h3>
                  <div className="crew-members-grid">
                    {filteredCrewMembers.map(member => (
                      <div 
                        key={member.id} 
                        className="crew-member-tile"
                        onClick={() => handleCrewMemberCheckbox(member.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedCrewMembers.includes(member.id)}
                          onChange={() => {}} // Empty onChange to avoid React warning
                          onClick={(e) => e.stopPropagation()} // Prevent double-triggering when clicking checkbox
                        />
                        <h4>{member.name}</h4>
                        <p>Position: {member.position || 'Not specified'}</p>
                        <p>Vehicle: {member.vehicle || 'Not assigned'}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </section>
              <section className="assigned-times">
                <h3>Assigned Times for {selectedDate.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}</h3>
                <div className="date-navigation">
                  <button onClick={() => handleDateChange(new Date(selectedDate.getTime() - 86400000))}>Previous Day</button>
                  <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="yyyy-MM-dd"
                  />
                  <button onClick={() => handleDateChange(new Date(selectedDate.getTime() + 86400000))}>Next Day</button>
                </div>
                <table className="calendar">
                  <thead>
                    <tr>
                      <th>Crew Member</th>
                      <th>Call Time</th>
                      <th>Wrap Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {crewMembers.map(member => {
                      const timeEntry = assignedTimes.find(time => time.userId === member.id);
                      return (
                        <tr key={member.id}>
                          <td>{member.name}</td>
                          <td>{timeEntry ? formatTime(timeEntry.startTime) : 'Not Assigned'}</td>
                          <td>{timeEntry ? formatTime(timeEntry.endTime) : 'Not Set'}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </section>
              {unassignedCrewMembers.length > 0 && (
                <div className="unassigned-crew-members">
                  <h3>Unassigned Crew Members:</h3>
                  <ul>
                    {unassignedCrewMembers.map(member => (
                      <li key={member.id}>{member.name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ) : (
            <div className="dispatch-run-content">
              <div className="dispatch-run-tile">
                <h2>Dispatch New Run</h2>
                {error && <div className="error-message">{error}</div>}
                {assignmentConfirmation && <div className="assignment-confirmation">{assignmentConfirmation}</div>}
                <form onSubmit={handleDispatchRun} className="dispatch-run-form">
                  <div className="form-group">
                    <label htmlFor="date">Date:</label>
                    <DatePicker
                      selected={newRun.date ? new Date(newRun.date) : null}
                      onChange={handleNewRunDateChange}
                      dateFormat="yyyy-MM-dd"
                      id="date"
                      name="date"
                      required
                      className="run-date-input"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="title">Run Title:</label>
                    <input
                      type="text"
                      id="title"
                      name="title"
                      value={newRun.title}
                      onChange={handleNewRunChange}
                      required
                      className="run-title-input"
                    />
                  </div>
                  <div className="form-group run-type">
                    <label>Run Type:</label>
                    <div className="run-type-buttons">
                      <button
                        type="button"
                        className={newRun.runType === 'pickup' ? 'active' : ''}
                        onClick={() => handleNewRunChange({ target: { name: 'runType', value: 'pickup' } })}
                      >
                        Pickup
                      </button>
                      <button
                        type="button"
                        className={newRun.runType === 'dropoff' ? 'active' : ''}
                        onClick={() => handleNewRunChange({ target: { name: 'runType', value: 'dropoff' } })}
                      >
                        Drop-off
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="address">
                      {newRun.runType === 'pickup' ? 'Pickup Address:' : 'Drop-off Address:'}
                    </label>
                    <textarea
                      id="address"
                      name="address"
                      value={newRun.address}
                      onChange={handleNewRunChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="itemDescription">
                      {newRun.runType === 'pickup' ? 'Item to be Picked Up:' : 'Item to be Dropped-off:'}
                    </label>
                    <textarea
                      id="itemDescription"
                      name="itemDescription"
                      value={newRun.itemDescription}
                      onChange={handleNewRunChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="picture">Add Picture:</label>
                    <input
                      type="file"
                      id="picture"
                      name="picture"
                      onChange={handlePictureChange}
                      accept="image/*"
                    />
                  </div>
                  <button type="submit" className="dispatch-run-button">Dispatch Run</button>
                </form>
              </div>
              <div className="dispatch-run-tile crew-selection">
                <h3>Crew Members</h3>
                <div className="form-group">
                  <label htmlFor="crewMember">Search Crew Member:</label>
                  <input
                    type="text"
                    id="crewMember"
                    name="crewMember"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search by name or position"
                  />
                  <div className="selection-buttons">
                    <button 
                      type="button" 
                      onClick={handleSelectAll}
                      className="select-all-button"
                    >
                      Check All
                    </button>
                    <button 
                      type="button" 
                      onClick={handleDeselectAll}
                      className="deselect-all-button"
                    >
                      Deselect All
                    </button>
                  </div>
                </div>
                <div className="crew-members-list">
                  <div className="crew-members-grid">
                    {filteredCrewMembers.map(member => (
                      <div 
                        key={member.id} 
                        className="crew-member-tile"
                        onClick={() => handleCrewMemberCheckboxForRun(member.id)}
                        style={{ cursor: 'pointer' }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedCrewMembersForRun.includes(member.id)}
                          onChange={() => {}} // Empty onChange to avoid React warning
                          onClick={(e) => e.stopPropagation()} // Prevent double-triggering when clicking checkbox
                        />
                        <h4>{member.name}</h4>
                        <p>Position: {member.position || 'Not specified'}</p>
                        <p>Vehicle: {member.vehicle || 'Not assigned'}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="dispatched-runs-tile">
                <h2>Dispatched Runs</h2>
                <div className="date-navigation">
                  <button onClick={handlePreviousDay}>&lt; Previous Day</button>
                  <span className="date-display">
                    {selectedDate.toLocaleDateString('en-US', { 
                      weekday: 'long', 
                      year: 'numeric', 
                      month: 'long', 
                      day: 'numeric' 
                    })}
                  </span>
                  <button onClick={handleNextDay}>Next Day &gt;</button>
                </div>
                <table className="dispatched-runs-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Title</th>
                      <th>Assigned To</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dispatchedRuns.map(run => (
                      <React.Fragment key={run.id}>
                        <tr 
                          onClick={() => handleRunClick(run.id)}
                          className={`run-row ${expandedRun === run.id ? 'expanded' : ''}`}
                          style={{ cursor: 'pointer' }}
                        >
                          <td>{formatDate(run.date.toDate())}</td>
                          <td>{run.title}</td>
                          <td>{run.assignedToName}</td>
                          <td>{run.status}</td>
                        </tr>
                        {expandedRun === run.id && (
                          <tr className="expanded-details">
                            <td colSpan="4">
                              <div className="run-details">
                                <p><strong>Run Type:</strong> {run.runType}</p>
                                <p><strong>Address:</strong> {run.address}</p>
                                <p><strong>Description:</strong> {run.itemDescription}</p>
                                {run.pictureUrl && (
                                  <div className="run-image">
                                    <img src={run.pictureUrl} alt="Run" />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="future-runs-tile">
                <h2>Future Runs</h2>
                <table className="dispatched-runs-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Title</th>
                      <th>Assigned To</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {futureRuns.map(run => (
                      <React.Fragment key={run.id}>
                        <tr 
                          onClick={() => handleRunClick(run.id)}
                          className={`run-row ${expandedRun === run.id ? 'expanded' : ''}`}
                          style={{ cursor: 'pointer' }}
                        >
                          <td>{run.dateObj.toLocaleDateString()}</td>
                          <td>{run.title}</td>
                          <td>{run.assignedToName}</td>
                          <td>{run.status}</td>
                        </tr>
                        {expandedRun === run.id && (
                          <tr className="expanded-details">
                            <td colSpan="4">
                              <div className="run-details">
                                <p><strong>Run Type:</strong> {run.runType}</p>
                                <p><strong>Address:</strong> {run.address}</p>
                                <p><strong>Description:</strong> {run.itemDescription}</p>
                                {run.pictureUrl && (
                                  <div className="run-image">
                                    <img src={run.pictureUrl} alt="Run" />
                                  </div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
      {toast && (
        <Toast 
          message={toast.message} 
          onClose={() => setToast(null)}
          duration={3000}
          type={toast.type}
        />
      )}
    </div>
  );
}

export default Dispatch;
