import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { useAuth } from '../hooks/useAuth';
import './Sidebar.css';
import './ResponsiveSidebar.css';
import logo from '../Assets/Slate-Logo.png';
import profilePlaceholder from '../Assets/scph/Profile placeholder.png';
import { collection, query, where, getDocs, onSnapshot, Timestamp, orderBy } from 'firebase/firestore';
import { db } from '../firebase';

function Sidebar({ isOpen }) {
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [error, setError] = useState(null);
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if (!user) return;

    const oneWeekAgo = Timestamp.fromDate(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));

    // Match the exact working queries from Notifications.js
    const timeEntriesQuery = query(
      collection(db, 'timeEntries'),
      where('createdAt', '>', oneWeekAgo),
      orderBy('createdAt', 'desc')
    );

    const runsQuery = query(
      collection(db, 'dispatchedRuns'),
      where('createdAt', '>', oneWeekAgo),
      orderBy('createdAt', 'desc')
    );

    const unsubscribeTime = onSnapshot(timeEntriesQuery, (snapshot) => {
      // Filter for this user's unacknowledged items
      const unreadCount = snapshot.docs.filter(doc => {
        const data = doc.data();
        return data.userId === user.uid && !data.acknowledged;
      }).length;
      console.log('Unread time entries:', unreadCount);
      setUnreadCount(prev => ({ ...prev, times: unreadCount }));
    });

    const unsubscribeRuns = onSnapshot(runsQuery, (snapshot) => {
      // Filter for this user's unacknowledged items
      const unreadCount = snapshot.docs.filter(doc => {
        const data = doc.data();
        return data.assignedTo === user.uid && !data.acknowledged;
      }).length;
      console.log('Unread runs:', unreadCount);
      setUnreadCount(prev => ({ ...prev, runs: unreadCount }));
    });

    return () => {
      unsubscribeTime();
      unsubscribeRuns();
    };
  }, [user]);

  // Calculate total unread count
  const totalUnread = Object.values(unreadCount).reduce((a, b) => a + b, 0);

  const fetchNewNotificationsCount = async () => {
    // Implement this function to fetch new notifications count
  };

  const handleLogout = async () => {
    const auth = getAuth();
    try {
      await signOut(auth);
      setUser(null);
      navigate('/');
    } catch (error) {
      console.error("Error signing out: ", error);
      setError("Failed to log out. Please try again.");
    }
  };

  if (!isOpen) return null;

  if (!user) {
    return null; // or return a loading indicator
  }

  const isAdmin = user && ['admin', 'productionAdmin', 'superAdmin', 'production_admin', 'app_admin'].includes(user.role);
  const isSupervisor = user && (user.role === 'supervisor' || user.role === 'Supervisor');

  return (
    <div className="sidebar">
      <div className="sidebar-header">
        <img src={logo} alt="Slate Fusion Logo" className="sidebar-logo" />
        <h1 className="sidebar-title">Slate Fusion</h1>
      </div>
      <ul className="sidebar-menu">
        <li><Link to="/welcome">👋 Welcome</Link></li>
        <li><Link to="/home">🏠 Home</Link></li>
        <li><Link to="/times">⏱️ Times</Link></li>
        <li><Link to="/messages">✉️ Messages</Link></li>
        <li>
          <Link to="/notifications">
            🔔 Notifications
            {totalUnread > 0 && (
              <span className="notification-count">{totalUnread}</span>
            )}
          </Link>
        </li>
        {(isAdmin || isSupervisor) && (
          <>
            <li><Link to="/dashboard">🎛️ Dashboard</Link></li>
            <li><Link to="/dispatch">📟 Dispatch</Link></li>
            <li><Link to="/reports">📊 Reports</Link></li>
            <li><Link to="/crew-members">👥 Crew Members</Link></li>
            <li><Link to="/departments">🎭 Departments</Link></li>
            <li><Link to="/vehicles-page">🚛 Production Vehicles</Link></li>
            <li><Link to="/budget">🧮 Budget</Link></li>
          </>
        )}
      </ul>
      <div className="sidebar-bottom">
        <ul className="sidebar-menu">
          <li><Link to="/profile">🪪 Profile</Link></li>
          <li><Link to="/settings">⚙️ Settings</Link></li>
        </ul>
      </div>
      <div className="sidebar-footer">
        <div className="user-info">
          <img src={user?.photoURL || profilePlaceholder} alt="User Avatar" className="user-avatar" />
          <div className="user-details">
            <span className="user-role">Role: {user?.role}</span>
            <span className="user-email">{user?.email}</span>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default Sidebar;
